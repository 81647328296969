import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import Router from 'next/router';
import { createSelector } from 'reselect';
import { api } from 'src/api'
import { DocumentAnswerV2Dto, FormElementPackageInfoResponseDto, FormElementsV2ResponseDto, FormElementV2RequestDto, FormElementV2ResponseDto, LoanDto, LoanViewType } from 'src/backend';
import { HTTP_STATUS } from 'src/constants/api';
import { ZipFileAction, ZipStatus } from 'src/constants/common';
import { DEFAULT_FROM_ELEMENT_DISPLAY_ORDER, OverViewPackageDisplay, Type as FormElementType } from 'src/constants/form-element';
import { RoleType } from 'src/constants/loan';
import { ZIP_MIME_TYPES } from 'src/constants/mimes';
import { FieldTaskType } from 'src/constants/task';
import { StorageType } from 'src/constants/template';
import { CustomEventType } from 'src/constants/ui';
import { addIdToUploadingIds, removeFormElementUploadingProgress, removeIdFromUploadingIds, setFormElementUploadingProgress, uploadDocument } from 'src/slices/documents';
import { checkFileForFieldTasks, checkFormElementForFieldTasks, checkFormElementIdForFieldTasks, getAllLoanUsersTasks } from 'src/slices/task';
import uiSlice, { handleRequestError, setZipFileActionCallback, toggleConnectionError } from 'src/slices/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { ZipFileElement } from 'src/types/common';
import { BulkCopyFormElementRequest, TFormElementPriority } from 'src/types/formelement';
import { Loan } from 'src/types/loan';
import { Task } from 'src/types/tasks';
import type { FormElement } from 'src/types/view';
import {
    getFileNameWithoutExtension
} from 'src/utils';
import { createCustomEvent } from 'src/utils/custom-events';
import { isFormElementEntityTopLevel } from 'src/utils/form-element/is-form-element-entity-top-level';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { sortFromElements } from 'src/utils/form-element-transformer';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { getFileFieldTasks } from 'src/utils/get-file-field-tasks';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { toast } from 'src/utils/toast';
import { getFileFromUrl } from 'src/utils/url/get-file-from-url';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { getFoldersAndFiles, getZipStatus } from 'src/utils/zip';

import { elementsTree } from './elementv2';
import { licenseKeysSlice } from './license-keys';
import { getLoan } from './loan';
import { loansManagerSlice } from './loan-manager';

interface FormElementState {
    pendingGetLoanFormElements?: Record<string, string>;
    currentlyEditing?: FormElement;
    formElements: Record<string, Record<string, FormElementV2ResponseDto>>;
    packageInfo: Record<string, Record<string, FormElementPackageInfoResponseDto>>;
    isPreviewDocumentPinned: boolean;
    idToDeleteAfterUpload: string;
    zipPasswordCallback?: (password: string) => void;
    shoeBoxFilesCount: number;
    allShoeBoxes: Record<string, Record<string, FormElement>>;
    expandedShoeBox: string;
    cardsDisplay: keyof typeof OverViewPackageDisplay;
    updatingFormElementIds: Record<string, string>;
    pinnedTask: {
        index: number;
        task: Task;
    };
    loading: boolean;
}

const initialState: FormElementState = {
    pendingGetLoanFormElements: {},
    currentlyEditing: null,
    formElements: {},
    packageInfo: {},
    isPreviewDocumentPinned: false,
    idToDeleteAfterUpload: null,
    shoeBoxFilesCount: 0,
    expandedShoeBox: null,
    allShoeBoxes: {},
    cardsDisplay: OverViewPackageDisplay.PRINCIPAL,
    updatingFormElementIds: {},
    pinnedTask: null,
    loading: false,
};

export const formElementSlice = createSlice({
    name: 'formElement',
    initialState,
    reducers: {
        addIdToUpdating(state: FormElementState, action: PayloadAction<string>): void {
            state.updatingFormElementIds[action.payload] = action.payload;
        },
        removeIdFromUpdating(state: FormElementState, action: PayloadAction<string>): void {
            delete state.updatingFormElementIds[action.payload];
        },
        setCurrentlyEditingFormElement(state: FormElementState, action: PayloadAction<FormElement | null>): void {
            state.currentlyEditing = action.payload
        },
        setPinnedTask(state: FormElementState, action: PayloadAction<{ index: number, task: Task }>): void {
            if (action.payload) {
                state.pinnedTask = {
                    index: action.payload.index,
                    task: action.payload.task
                };
            } else {
                state.pinnedTask = null;
            }
        },
        getLoanFormElements(state: FormElementState, action: PayloadAction<{ loanId: string, formElements: Record<string, FormElementV2ResponseDto> }>): void {
            if (!isEqual(state.formElements[action.payload.loanId], action.payload.formElements)) {
                state.formElements = {
                    ...state.formElements,
                    [action.payload.loanId]: action.payload.formElements
                }
            }
        },
        setLoanPackageInfo(state: FormElementState, action: PayloadAction<{ loanId: string, packageInfo: Record<string, FormElementPackageInfoResponseDto> }>): void {
            if (!isEqual(state.packageInfo[action.payload.loanId], action.payload.packageInfo)) {
                state.packageInfo = {
                    ...state.packageInfo,
                    [action.payload.loanId]: action.payload.packageInfo
                }
            }
        },
        setPendingGetFormElements(state: FormElementState, action: PayloadAction<string>): void {
            // add or remove pending get loan form elements
            if (state.pendingGetLoanFormElements[action.payload]) {
                delete state.pendingGetLoanFormElements[action.payload]
            } else {
                state.pendingGetLoanFormElements[action.payload] = action.payload
            }
        },
        setDocumentPreviewPinned(state: FormElementState, action: PayloadAction<boolean>): void {
            state.isPreviewDocumentPinned = action.payload
        },
        setIdToDeleteAfterUpload(state: FormElementState, action: PayloadAction<string>): void {
            state.idToDeleteAfterUpload = action.payload;
        },
        setZipPasswordCallback(state: FormElementState, action: PayloadAction<(password: string) => void>): void {
            state.zipPasswordCallback = action.payload
        },
        setExpandedShoeBox(state: FormElementState, action: PayloadAction<string>): void {
            state.expandedShoeBox = action.payload
        },
        setCardsDisplay(state: FormElementState, action: PayloadAction<keyof typeof OverViewPackageDisplay>): void {
            state.cardsDisplay = action.payload
        },
        setLoading(state: FormElementState, action: PayloadAction<boolean>): void {
            state.loading = action.payload
        }
    }
});

export const { reducer } = formElementSlice;

export const addIdToUpdating = (formElementId: string): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.addIdToUpdating(formElementId));
}

export const setFormElementsLoading = (loading: boolean): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.setLoading(loading));
}

export const removeIdFromUpdating = (formElementId: string): AppThunk => dispatch => {
    dispatch(formElementSlice.actions.removeIdFromUpdating(formElementId));
}

export const setCurrentlyEditingFormElement = (formElement: FormElement): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setCurrentlyEditingFormElement(formElement));
}

export const setExpandedShoeBox = (shoeBoxId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setExpandedShoeBox(shoeBoxId));
}

export const setPinnedTask = (payload: { index: number, task: Task } | null): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setPinnedTask(payload));
}
export const setCardsDisplay = (cardsDisplay: keyof typeof OverViewPackageDisplay, loanId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setCardsDisplay(cardsDisplay));
    await dispatch(getLoanFormElements(loanId));
}
// Deprecated
export const updateFormElementDueDate = ({ formElementId, dueDate, loanId }: { formElementId: string, dueDate: string, loanId: string }): AppThunk => async (dispatch): Promise<void> => {
    await api.updateFormElementDueDate({ formElementId, dueDate });
    dispatch(getLoanFormElements(loanId));
}

export const updateFormElementPriority = ({ formElementId, priority, loanId }: { formElementId: string, priority: TFormElementPriority, loanId: string }): AppThunkPromise => async (dispatch, getState): Promise<void> => {
    await api.updateFormElementPriority({ formElementId, priority });
}

export const postAssignFormElementToUser = ({ formElementId, userId, loanId, assignRecursively }: { formElementId: string, userId: string, loanId: string, assignRecursively?: boolean }): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(getLoanFormElements(loanId));
    await api.postAssignFormElementToUser({ formElementId, userId, assignRecursively });
    dispatch(checkFormElementIdForFieldTasks({ loanId, formElementId }));
    dispatch(autoAssignFirstSignatureFieldToUser({ loanId, formElementId, userId }));
}

export const autoAssignFirstSignatureFieldToUser = ({ formElementId, userId, loanId }: { formElementId: string, userId: string, loanId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    // get all tasks for form element answer
    const { formElement: { formElements }, view: { user }, [loansManagerSlice.name]: { loans }, [licenseKeysSlice.name]: { pdftronKey } } = getState();
    const loan: Loan = loans[loanId];
    const borrowerRole = loan.loanRoles.find(loanRole => loanRole.user.id === userId && (loanRole.role === RoleType.BORROWER || loanRole.role === RoleType.LeadBorrower))
    if (!borrowerRole) {
        return;
    }
    const loanFormElements = Object.values(formElements[loanId]);
    const formElement = loanFormElements.find(formElement => formElement.id === formElementId);
    if (!!formElement?.answer) {
        const itemDownloadUrl = await api.getDocumentDownloadUrl(formElement.answer?.document?.id);
        const itemFile = await getFileFromUrl(itemDownloadUrl, formElement.answer?.document?.name);
        const tasks = await getFileFieldTasks(itemFile, user.id, pdftronKey);
        const assignSigneeTask = tasks.find(task => task.type === FieldTaskType.ASSIGN_SIGNEE);
        const signTask = tasks.find(task => task.fieldType === FieldTaskType.SIGN);

        if (!!assignSigneeTask && !signTask) {
            const event = createCustomEvent(CustomEventType.AssignSignatureFieldToUser, {
                userId: userId,
                userName: getUserDisplayName(borrowerRole.user),
                annotationId: assignSigneeTask.fieldId,
            });
            window.dispatchEvent(event);
        }
    }
}


export const setZipPasswordCallback = (callback: (password: string) => void): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setZipPasswordCallback(callback));
}

export const uploadZipFile = ({ formElementId, loanId, files }: { formElementId: string, loanId: string, files: File[] }): AppThunkPromise => async (dispatch, getState): Promise<any> => {
    const { ui: { dadZipFileHandling } } = getState();
    dispatch(addIdToUploadingIds(formElementId));
    const promises = files.map(async file => {
        return new Promise(async (resolve, reject) => {
            const zipStatus = await getZipStatus(file);
            let password = undefined;
            if (zipStatus === ZipStatus.ENCRYPTED) {
                password = await new Promise((resolvePassword) => {
                    dispatch(formElementSlice.actions.setZipPasswordCallback(resolvePassword));
                });
                dispatch(formElementSlice.actions.setZipPasswordCallback(null));
            }
            let action = undefined;
            if (dadZipFileHandling === ZipFileAction.ASK) {
                action = await new Promise((resolveAction) => {
                    dispatch(setZipFileActionCallback(resolveAction));
                })
            }
            if (action === ZipFileAction.IGNORE) {
                dispatch(uploadMultipleFilesAndAnswerFormElement({ files, parentId: formElementId, loanId, ignoreZip: true }));
                reject();
            } else {

                try {
                    const { tree } = await getFoldersAndFiles(file, password);
                    await dispatch(recursivelyCreateFormElements({ tree, formElementId, loanId }));
                } catch (error) {
                    toast({
                        content: error.message,
                        type: 'error'
                    });
                    reject(error);
                }
            }
            resolve(true);
        })
    });
    await Promise.allSettled(promises);
    dispatch(removeIdFromUploadingIds(formElementId));
}

export const recursivelyCreateFormElements = ({ formElementId, loanId, tree }: { formElementId: string, loanId: string, tree: ZipFileElement[] }): AppThunkPromise => async (dispatch): Promise<void> => {
    for (const element of tree) {
        const { name, type } = element;
        dispatch(addIdToUploadingIds(formElementId));
        const [createdFormElement] = await dispatch(
            createFormElements({
                formElements: [{
                    title: name,
                    storageType: type,
                    assignedToUserId: null,
                    displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
                    hasExpiration: false,
                    parentId: formElementId,
                    loanId,
                }],
                loanId
            })
        );
        if (type === "FOLDER" && createdFormElement?.id) {
            await dispatch(recursivelyCreateFormElements({ formElementId: createdFormElement.id, loanId, tree: element.children }));
        } else if (type === FormElementType.FILE && createdFormElement?.id) {
            await dispatch(uploadDocument({
                formElement: createdFormElement,
                loanId,
                file: element.file,
                type: 'FormElement'
            }));
        }
        dispatch(removeIdFromUploadingIds(formElementId));
    }
}

export const updateFormElement = ({ formElementId, loanId, payload }: {
    formElementId: string,
    loanId: string,
    payload: {
        title: string;
        assignedToUserId: string;
        hasExpiration: boolean;
        expireDate: string;
    }
}): AppThunkPromise => async (dispatch): Promise<any> => {


    const { statusCode, errors, data } = await api.putFormElement({ formElementId, payload });
    if (statusCode === HTTP_STATUS.OK) {
        dispatch(getLoanFormElements(loanId));
        return { data }
    } else {
        return { errors };
    }
}


export const deleteFormElements = ({ multiSelect = false, formElementIds, loanId }: { multiSelect: boolean; formElementIds: string[], loanId: string }): AppThunk => async (dispatch): Promise<void> => {
    try {
        await api.deleteV2FormElements({
            multiSelect,
            elements: formElementIds.map(id => ({
                id: id,
                loanId
            })) as FormElementV2RequestDto[]
        });
        dispatch(deleteFormElementsEntities({ formElementIds, loanId }));
        dispatch(getLoanFormElements(loanId));
        dispatch(getLoan(loanId));
    } catch {
        toast({
            content: 'Unable to delete',
            type: 'error'
        })
    }
}

export const deleteFormElementsEntities = ({ formElementIds, loanId }: { formElementIds: string[], loanId: string }): AppThunk => async (_, getState): Promise<void> => {
    const { formElement: { formElements }, [loansManagerSlice.name]: { loans } } = getState();
    const loan = loans[loanId];
    const loanFormElements = formElements[loanId];
    if (!loanFormElements || !loan) return;
    const loanFormElementsList = Object.values(loanFormElements)
    const entityFormElements = loanFormElementsList
        // get all form elements set to be deleted
        .filter(formElement => formElementIds.includes(formElement.id))
        // get all form elements that are entities
        .filter(formElement => !!formElement.sherpaEntityId)
        // get all form elements that are top level entities
        .filter(formElement => isFormElementEntityTopLevel(formElement, loanFormElementsList));

    const entitiesList = loan.loanEntities.filter(entity => entityFormElements.some(formElement => formElement.sherpaEntityId === entity?.sherpaEntity?.id));

    entitiesList.forEach(entity => {
        api.removeEntityFromLoan(loanId, entity.id);
    });
}

const optimisticUpdateFormElements = ({ formElements, loanId, userId, approved, rejected }: { approved: boolean; rejected: boolean, formElements: Partial<FormElementV2ResponseDto>[], loanId: string, userId?: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const { formElement: { formElements: formElementsState, packageInfo: packageInfoState } } = getState();
    const loanFormElements = formElementsState[loanId];
    const loanPackageInfo = packageInfoState[loanId];

    if (!loanFormElements || !loanPackageInfo) return;

    const loanFormElementsList = Object.values(loanFormElements);
    const loanPackageInfoList = Object.values(loanPackageInfo);

    const formElementsToUpdate = loanFormElementsList.map(formElement => {
        const packageInfo = loanPackageInfoList.find(packageInfo => packageInfo.elementId === formElement.id);
        if (!packageInfo) return formElement;
        const packageInfoToUpdate = formElements.find(formElement => formElement.id === packageInfo.id);
        if (!packageInfoToUpdate) return formElement;

        return {
            ...formElement,
            rejected,
            rejectedByUser: {
                id: userId,
                givenName: '',
                familyName: '',
            },
            approved,
            approvedByUser: {
                id: userId,
                givenName: '',
                familyName: '',
            }
        }
    }).reduce((acc, formElement) => {
        if (!formElement) return acc;
        return {
            ...acc,
            [formElement.id]: formElement
        }
    }, {});
    dispatch(setLoanFormElements({ loanId, formElements: formElementsToUpdate }));

}

export const approveFormElements = ({ multiSelect = false, formElements, loanId, userId }: { multiSelect: boolean; formElements: FormElementV2ResponseDto[], loanId: string, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const formElementsWithFiles = formElements.filter(formElement => formElement.storageType === FormElementType.FILE && !!formElement?.answer?.document?.id);
    if (formElementsWithFiles.length > 0) {
        dispatch(optimisticUpdateFormElements({ approved: true, rejected: false, formElements, loanId, userId }))
        try {
            dispatch(updateFormElements({
                multiSelect,
                formElements: formElementsWithFiles.map(formElement => ({
                    id: formElement.id,
                    approvedByUserId: userId,
                    loanId
                } as FormElementV2RequestDto)),
                loanId
            }));
        } catch (error) {
            dispatch(optimisticUpdateFormElements({ approved: false, rejected: false, formElements, loanId, userId }))
        }
        formElementsWithFiles.forEach(formElement => {
            api.postTasksForV2Element(formElement.id, []);
        })
    }
}

export const rejectFormElements = ({ multiSelect = false, formElements, loanId, userId }: { multiSelect: boolean; formElements: FormElementV2ResponseDto[], loanId: string, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const formElementsWithFiles = formElements.filter(formElement => formElement.storageType === FormElementType.FILE && !!formElement?.answer?.document?.id);
    if (formElementsWithFiles.length > 0) {
        dispatch(optimisticUpdateFormElements({ rejected: true, approved: false, formElements, loanId, userId }))
        try {
            dispatch(updateFormElements({
                multiSelect,
                formElements: formElementsWithFiles.map(formElement => ({
                    id: formElement.id,
                    rejectedByUserId: userId,
                    loanId
                } as FormElementV2RequestDto)),
                loanId
            }));
        } catch (error) {
            dispatch(optimisticUpdateFormElements({ rejected: false, approved: false, formElements, loanId, userId }))
        }
        formElementsWithFiles.forEach(formElement => {
            api.postTasksForV2Element(formElement.id, []);
        })
    }
}

export const unApproveFormElement = (args: { formElements: FormElementV2ResponseDto[], loanId: string, multiSelect: boolean, userId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const { view: { user } } = getState();
    dispatch(optimisticUpdateFormElements({
        approved: false,
        rejected: false,
        formElements: args.formElements.map(formElement => ({
            ...formElement,
            approved: false,
            userId: args.userId
        })),
        loanId: args.loanId
    }))
    try {
        dispatch(updateFormElements({
            multiSelect: false,
            formElements: args.formElements.map(formElement => ({
                id: formElement.id,
                approved: false,
                loanId: args.loanId
            })),
            loanId: args.loanId
        }));
    } catch (error) {
        dispatch(optimisticUpdateFormElements({
            approved: false, rejected: false, formElements: args.formElements.map(formElement => ({
                id: formElement.id,
                approved: false,
                userId: args.userId
            })), loanId: args.loanId
        }))
    }
    args.formElements.forEach(formElement => {
        dispatch(checkFormElementForFieldTasks({
            loanId: args.loanId,
            formElement: {
                ...formElement,
                approved: false,
            },
            loggedInUserId: user.id
        }))
    })
}

// answer form element
export const answerFileFormElement = ({ formElementId, documentId, loanId, answerId = null }): AppThunkPromise => async (dispatch, getState): Promise<FormElementV2ResponseDto> => {
    const { formElement: { idToDeleteAfterUpload } } = getState();
    if (idToDeleteAfterUpload) {
        dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
    }
    const formElement = await api.addAnswerToV2Element({
        answerId,
        elementId: formElementId,
        documentId,
    });
    await api.postTasksForV2Element(formElementId, []);
    await dispatch(getLoanFormElements(loanId));
    await dispatch(getAllLoanUsersTasks(loanId));
    dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
    dispatch(setIdToDeleteAfterUpload(null));
    return formElement;
}

export const addDocumentSection = ({ document, formElementId, loanId }: { document: DocumentAnswerV2Dto, formElementId: string, loanId: string }): AppThunkPromise => async (dispatch, getState): Promise<FormElementV2ResponseDto> => {
    const { formElement: { idToDeleteAfterUpload } } = getState();
    if (idToDeleteAfterUpload) {
        dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
    }
    const [addedFormElement] = await dispatch(createFormElements({
        formElements: [{
            title: getFileNameWithoutExtension(document.name),
            storageType: StorageType.FILE,
            displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
            hasExpiration: false,
            parentId: formElementId,
            loanId
        }],
        loanId
    }));

    await api.addAnswerToV2Element({
        answerId: null,
        elementId: addedFormElement.id,
        documentId: document.id
    });
    if (document.createdByUser) {
        await dispatch(getAllLoanUsersTasks(loanId));
    }
    if (idToDeleteAfterUpload) {
        dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
        dispatch(setIdToDeleteAfterUpload(null));
    }

    return addedFormElement;
}

// get upload url for multiple files and upload them and create child form element for each and answer them
export const uploadMultipleFilesAndAnswerFormElement = ({
    extraFields = [],
    parentId,
    loanId,
    files,
    ignoreZip,
    sherpaEntityId }: { parentId: string, loanId: string, files: File[], ignoreZip?: boolean, sherpaEntityId?: string, extraFields?: Partial<FormElementV2RequestDto>[] }): AppThunk => async (dispatch, getState: () => RootState): Promise<void> => {
        const { ui: { dadZipFileHandling }, formElement: { idToDeleteAfterUpload, formElements }, [licenseKeysSlice.name]: { pdftronKey } } = getState();
        const loanFormElements = formElements[loanId];
        const targetFormElement = loanFormElements[parentId];

        // dispatch upload zip file
        const zipFiles = files.filter(file => ZIP_MIME_TYPES.includes(file.type as any) &&
            dadZipFileHandling !== ZipFileAction.IGNORE &&
            !ignoreZip);
        if (zipFiles.length) {
            dispatch(uploadZipFile({ files: zipFiles, loanId, formElementId: parentId }));
        }
        // upload other document the normal way
        const filteredIndexes = [];

        const otherFiles = files.filter((file, index) => {
            if (!ZIP_MIME_TYPES.includes(file.type as any) ||
                dadZipFileHandling === ZipFileAction.IGNORE ||
                ignoreZip) {
                filteredIndexes.push(index);
                return true;
            }

            return false
        });

        const filteredFields = extraFields.filter((_field, index) => filteredIndexes.includes(index));

        if (otherFiles.length === 0) {
            return;
        }
        dispatch(addIdToUploadingIds(parentId));
        const clearUploadProgressIds = [];
        try {
            const createFileFormElementsPromises = [];
            otherFiles.forEach((file, index) => {
                createFileFormElementsPromises.push(new Promise(async (resolve, reject) => {
                    try {
                        const [createdFormElement] = await dispatch(createFormElements({
                            formElements: [{
                                displayOrder: DEFAULT_FROM_ELEMENT_DISPLAY_ORDER,
                                title: getFileNameWithoutExtension(file.name),
                                storageType: StorageType.FILE,
                                hasExpiration: false,
                                parentId,
                                sherpaEntityId,
                                loanId,
                                ...filteredFields[index]
                            }],
                            loanId
                        }));
                        dispatch(addIdToUploadingIds(createdFormElement.id));
                        const uploadResult = await uploadFileToDocumentId({
                            file,
                            loanId,
                            formElementId: createdFormElement.id,
                            name: createdFormElement.title,
                            pdfTronKey: pdftronKey,
                            progress: (percent) => {
                                dispatch(setFormElementUploadingProgress(createdFormElement.id, percent));
                            }
                        });
                        await dispatch(answerFileFormElement({
                            formElementId: createdFormElement.id,
                            documentId: uploadResult.documentId,
                            loanId
                        }));
                        if (getExtensionFromFilename(file.name) === 'pdf') {
                            dispatch(checkFileForFieldTasks({
                                formElementId: createdFormElement.id,
                                loanId,
                                file: uploadResult.file,
                            }));
                        }
                        dispatch(removeIdFromUploadingIds(createdFormElement.id));
                        clearUploadProgressIds.push(createdFormElement.id);
                        resolve(true);
                    } catch (error) {
                        reject(error);
                    }
                }));
            });

            const results = await Promise.allSettled(createFileFormElementsPromises);

            // get the last fulfilled promise
            const hasFulfilled = results.find(result => result.status === 'fulfilled');
            if (!hasFulfilled) {
                dispatch(setIdToDeleteAfterUpload(null));
                throw Error("Couldn't create form elements");
            }
            if (idToDeleteAfterUpload) {
                dispatch(addIdToUploadingIds(idToDeleteAfterUpload));
            }

            if (!!hasFulfilled) {
                await dispatch(getLoanFormElements(loanId));
            }
            if (targetFormElement) {
                dispatch(getAllLoanUsersTasks(loanId));
            }
        } catch (error) {
            notifyBugTracker(error);
        } finally {
            if (idToDeleteAfterUpload) {
                dispatch(removeIdFromUploadingIds(idToDeleteAfterUpload));
            }
            dispatch(setIdToDeleteAfterUpload(null));
            dispatch(removeIdFromUploadingIds(parentId));
            clearUploadProgressIds.forEach(id => dispatch(removeFormElementUploadingProgress(id)));
        }
    }

const getQueryLoanViewType = (loanViewType?: LoanViewType): LoanViewType => {
    if (loanViewType) {
        return loanViewType;
    }
    const QueryLoanViewType = String(Router.query.loanViewType) as LoanViewType;
    return [
        LoanViewType.CONSOLIDATED_LENDER,
        LoanViewType.CONVENTIONAL,
        LoanViewType.SBA504,
        LoanViewType.SBA7A
    ].includes(QueryLoanViewType) ? QueryLoanViewType : LoanViewType.CONVENTIONAL;
}

let PendingAbortControllers = [];

export const getLoanFormElements = (loanId: string, clearPendingRequest: boolean = false, loanViewType?: LoanViewType): AppThunkPromise => async (dispatch, getState): Promise<void> => {
    const { [uiSlice.name]: { connectionError } } = getState();
    if (PendingAbortControllers.length && !clearPendingRequest) {
        return;
    }
    if (clearPendingRequest && PendingAbortControllers.length) {
        PendingAbortControllers.forEach(abortController => abortController.abort());
    }
    dispatch(formElementSlice.actions.setPendingGetFormElements(loanId));
    try {
        const pendingAbortController = new AbortController();
        PendingAbortControllers.push(pendingAbortController);
        const result = await api.getV2FormElements({ loanId, loanViewType: getQueryLoanViewType(loanViewType), signal: pendingAbortController.signal });
        dispatch(setLoanFormElements({ loanId, formElements: result.elements }));
        dispatch(formElementSlice.actions.setLoanPackageInfo({ loanId, packageInfo: result.packageInfo }));
        dispatch(getAllLoanUsersTasks(loanId));
        if (connectionError) {
            dispatch(toggleConnectionError(false));
        }
        dispatch(setFormElementsLoading(false));
    } catch (error) {
        dispatch(handleRequestError(error));
    } finally {
        PendingAbortControllers.shift();
        dispatch(formElementSlice.actions.setPendingGetFormElements(loanId));
    }
}

export const setLoanFormElements = ({ loanId, formElements }: { loanId: string, formElements: Record<string, FormElementV2ResponseDto> }): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(formElementSlice.actions.getLoanFormElements({ loanId, formElements }));
}

export const updateFormElementTitle = ({ formElementId, title, loanId }: { formElementId: string, title: string, loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    await api.updateV2FormElements({
        multiSelect: false,
        elements: [{
            id: formElementId,
            title: title?.trim(),
            loanId
        }]
    });
    dispatch(getLoanFormElements(loanId));
}

export const updateFormElements = ({ multiSelect = false, formElements, loanId }: { multiSelect: boolean, formElements: Partial<FormElementV2RequestDto>[], loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    if (formElements.length > 0) {
        await api.updateV2FormElements({
            multiSelect,
            elements: formElements,
        });
        await dispatch(getLoanFormElements(loanId));
    }
}

export const applyNewTemplateToExistingLoan = ({ loanId, templateId }: { loanId: string, templateId: string }): AppThunkPromise<LoanDto> => async (dispatch): Promise<LoanDto> => {
    const loan = await api.applyNewTemplateToExistingLoan({ loanId, templateId });
    dispatch(getLoanFormElements(loanId));
    return loan;
}

export const createFormElements = ({ formElements, loanId }: { formElements: Partial<FormElementV2RequestDto>[], loanId: string }): AppThunkPromise<FormElementV2ResponseDto[]> => async (dispatch): Promise<FormElementV2ResponseDto[]> => {
    if (formElements.length > 0) {
        const result = await api.createV2FormElements({
            elements: formElements,
        });
        dispatch(getLoanFormElements(loanId));
        if (result?.packageInfo) {
            const createdPackageInfoList = Object.values(result.packageInfo);
            return createdPackageInfoList.map(packageInfo => {
                return ({
                    ...result.elements[packageInfo.elementId],
                    id: packageInfo.id,
                    parentId: packageInfo.parentInfoId,
                    childrenIds: packageInfo.childrenIds,
                    title: packageInfo.title,
                    locations: packageInfo.locations,
                    hasWorkflow: packageInfo.hasWorkflow,
                })
            })
        }
    }
}


export const copyFormElements = ({ params, loanId }: { params: BulkCopyFormElementRequest, loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    if (params.idsToCopy.length > 0) {
        await api.copyElements({
            loanId,
            newParentId: params.targetSection,
            sourceIds: params.idsToCopy,
        });
    }
}

export const updateFormElementsDisplayOrder = ({ items, loanId }: { items: Pick<FormElement, 'id' | 'displayOrder'>[], loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    const promises = items.map(formElement => api.updateFormElementDisplayOrder({ formElementId: formElement.id, displayOrder: formElement.displayOrder }));
    await Promise.all(promises);
    dispatch(getLoanFormElements(loanId));
}

export const setDocumentPreviewPinned = (pinned: boolean): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setDocumentPreviewPinned(pinned));
}

export const deleteFormElementAnswer = ({ formElement, loanId }: { formElement: Partial<FormElementV2ResponseDto>, loanId: string }): AppThunk => async (dispatch): Promise<void> => {
    try {
        await api.deleteAnswerFromV2Element({
            elementId: formElement.id,
            answerId: formElement.answer.id,
            documentId: formElement.answer.document.id
        });
        dispatch(getLoanFormElements(loanId));
    } catch {
        toast({
            content: `Unable to delete answer for ${formElement.title}`,
            type: 'error'
        })
    }
}

export const setIdToDeleteAfterUpload = (id: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(formElementSlice.actions.setIdToDeleteAfterUpload(id));
}

export const resetFormElementAnswer = ({ formElementId, loanId }: { formElementId: string, loanId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(addIdToUploadingIds(formElementId));
    await api.resetTemplateAnswerOnElement(formElementId, { id: formElementId });
    dispatch(getLoanFormElements(loanId));
    dispatch(removeIdFromUploadingIds(formElementId));
}


export const loanFormElementsSelector = createSelector([
    (state: RootState) => state.view.currentLoanId,
    (state: RootState) => state.formElement.formElements,
    (state: RootState) => state.formElement.packageInfo,
],
    (currentLoanId, formElements, packageInfo) => {
        if (!currentLoanId || !packageInfo[currentLoanId] || !formElements[currentLoanId]) return []
        const loanPackageInfo = packageInfo[currentLoanId];
        const loanFormElements = formElements[currentLoanId];
        const packageInfoList = Object.values(loanPackageInfo);
        const formElementsList: FormElementV2ResponseDto[] = packageInfoList.map((packageInfo) => {

            return ({
                ...loanFormElements[packageInfo.elementId],
                id: packageInfo.id,
                parentId: packageInfo.parentInfoId,
                title: packageInfo.title,
                childrenIds: packageInfo.childrenIds,
                locations: packageInfo.locations,
                hasWorkflow: packageInfo.hasWorkflow,
            })
        })

        if (!formElementsList) {
            return []
        }
        // @ts-ignore
        return formElementsList.sort(sortFromElements);;
    }
);

export const selectFormElementIsLoading = (loanId: string) => (state: RootState) => typeof state.formElement.formElements[loanId] === 'undefined';

export const loanFormElementsAsListSelector = createSelector([
    (state: RootState) => state.view.currentLoanId,
    (state: RootState) => state.formElement.formElements
],
    (currentLoanId, formElements) => {
        if (!currentLoanId || !formElements[currentLoanId]) return []

        return Object.values(formElements[currentLoanId])
    }
);

export const loanIdFormElementsSelector = (id: string) => createSelector([(state: RootState) => state.formElement.formElements],
    (formElements) => {
        if (!id) return []

        return (formElements[id] ? Object.values(formElements[id]) : []) as FormElementV2ResponseDto[]
    }
);

export const currentLoanShoeBoxesSelector = createSelector(
    (state: RootState) => state.view.currentLoanId,
    (state: RootState) => state.formElement.allShoeBoxes,
    (currentLoanId, allShoeBoxes) => {
        if (!currentLoanId) return {}
        return allShoeBoxes[currentLoanId] ? allShoeBoxes[currentLoanId] : {}
    }
);

export const selectCachedFormElementsLoansIds = createSelector((state: RootState) => state.formElement.formElements, (formElements) => {
    return Object.keys(formElements)
});

export const formElementSelectorById = ({ loanId, formElementId }: { formElementId: string, loanId: string }) => createSelector(
    (state: RootState) => state.formElement.formElements,
    (formElements) => {
        if (!loanId || !formElementId) return null
        return formElements[loanId]?.[formElementId] ?? null
    }
);

export const selectFormElementIdIsUpdating = (loanId: string) => createSelector(
    (state: RootState) => state.formElement.updatingFormElementIds,
    (updatingFormElementIds) => updatingFormElementIds?.[loanId] !== undefined
);

export const v2FormElementsTreeSelector = (loanId: string) => createSelector([
    (state: RootState) => state.formElement.formElements[loanId],
    (state: RootState) => state.formElement.packageInfo[loanId]
],
    (formElements, packageInfo) => {
        if (!loanId || !formElements || !packageInfo) return []
        const packageInfoList = Object.values(packageInfo);
        const formElementsList = packageInfoList.map((packageInfo) => {

            return ({
                ...formElements[packageInfo.elementId],
                id: packageInfo.id,
                parentId: packageInfo.parentInfoId ?? null,
                childrenIds: packageInfo.childrenIds,
                title: packageInfo.title,
                priorityType: packageInfo.priorityType,
                locations: packageInfo.locations,
                hasWorkflow: packageInfo.hasWorkflow,
            })
        });
        if (!formElementsList) {
            return []
        }

        const rootFormElement = formElementsList.find((formElement) => formElement.storageType === StorageType.FOLDER && !formElement.parentId);

        if (!rootFormElement) {
            return []
        }

        return elementsTree(formElementsList, 'id', [rootFormElement.id])
    });

export const formElementsLoadingSelector = (state: RootState) => state.formElement.loading


export const v2FormElementsPackageSelector = (loanId: string) => createSelector([
    (state: RootState) => state.formElement.formElements[loanId],
    (state: RootState) => state.formElement.packageInfo[loanId]
],
    (formElements, packageInfo) => {
        if (!loanId || !formElements || !packageInfo) return []
        const packageInfoList = Object.values(packageInfo);
        const formElementsList: FormElementV2ResponseDto[] = packageInfoList.map((packageInfo) => {

            return ({
                ...formElements[packageInfo.elementId],
                id: packageInfo.id,
                parentId: packageInfo.parentInfoId ?? null,
                childrenIds: packageInfo.childrenIds,
                title: packageInfo.title,
                priorityType: packageInfo.priorityType,
                locations: packageInfo.locations,
                hasWorkflow: packageInfo.hasWorkflow,
            })
        })

        if (!formElementsList) {
            return []
        }
        // @ts-ignore
        return formElementsList.sort(sortFromElements);
    });

export const v2EntityFormElementsTreeSelector = (id: string, entityId: string) => createSelector([
    (state: RootState) => state.formElement.formElements[id],
    (state: RootState) => state.formElement.packageInfo[id],
],
    (formElements, packageInfo) => {
        if (!id || !formElements || !packageInfo || !entityId) return []
        const packageInfoList = Object.values(packageInfo);
        // dedupe packageInfoList from items with same elementId
        const deDupedPackageInfoList = packageInfoList.reduce((acc, curr) => {
            const found = acc.find((item) => item.elementId === curr.elementId);
            if (!found) {
                acc.push(curr);
            }
            return acc;
        }, [] as FormElementPackageInfoResponseDto[]);

        const formElementsList = deDupedPackageInfoList.map((packageInfo) => {

            return ({
                ...formElements[packageInfo.elementId],
                id: packageInfo.id,
                parentId: packageInfo.parentInfoId ?? null,
                elementId: packageInfo.elementId,
                childrenIds: packageInfo.childrenIds,
                title: packageInfo.title,
                priorityType: packageInfo.priorityType,
                locations: packageInfo.locations,
                hasWorkflow: packageInfo.hasWorkflow,
            })
        });

        const entityFormElements = formElementsList.filter((formElement) => formElement.sherpaEntityId === entityId);

        // filter form elements that exist in another form element childrenIds
        const filteredEntityFormElements = entityFormElements.filter((formElement) => {
            const parentFormElement = entityFormElements.find((item) => item.id === formElement.parentId);
            if (!parentFormElement) {
                return true;
            }
            return !parentFormElement.childrenIds.includes(formElement.id);
        });


        const entityFormElementsIds = filteredEntityFormElements.map((formElement) => formElement.id);
        if (!entityFormElementsIds.length) {
            return []
        }
        return elementsTree(formElementsList, 'id', entityFormElementsIds)
    });

