import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { LoanStageCategory } from 'src/constants/loan';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { LoanPhaseDto } from 'src/types/api';
import { sortPhasesByDisplayOrder } from 'src/utils/loan/sort-phases-by-display-order';

const sortByDisplayOrder = (phases: LoanPhaseDto[]): LoanPhaseDto[] => phases.sort((a, b) => a.displayOrder - b.displayOrder);

interface ListsState {
    phases: Record<string, LoanPhaseDto>;
}

const initialState: ListsState = {
    phases: {}
};

export const loanPhaseSlice = createSlice({
    name: 'loanStages',
    initialState,
    reducers: {
        setLoanStages(state: ListsState, action: PayloadAction<LoanPhaseDto[]>): void {
            state.phases = action.payload.reduce((acc, stage) => {
                acc[stage.id] = stage;
                return acc;
            }, {});
        }
    }
});

export const { reducer } = loanPhaseSlice;

export const deleteLoanStage = (phases: LoanPhaseDto[]): AppThunk => async (dispatch, getState): Promise<void> => {
    await api.deleteCompanyLoanPhases({
        phases: phases.map((stage) => ({
            id: stage.id,
        }))
    });
    dispatch(getLoanStagesByCompanyId(phases?.[0].lenderId));
};

export const addLoanStage = (loanStages: LoanPhaseDto[]): AppThunk => async (dispatch, getState): Promise<void> => {
    const { loanStages: { phases } } = getState();
    // update existing phases from loanStages
    const updatedStages = Object.keys(phases).map((stageKey) => {
        const updatedStage = loanStages.find((loanStage) => loanStage.id === phases[stageKey].id);
        return updatedStage || phases[stageKey];
    });
    // add new phases
    const newLoanStages = [...updatedStages];
    dispatch(loanPhaseSlice.actions.setLoanStages(sortByDisplayOrder(newLoanStages)));
    await api.createOrUpdateCompanyLoanPhases({ phases: loanStages });
    dispatch(getLoanStagesByCompanyId(loanStages?.[0].lenderId));
};

export const getLoanStagesByCompanyId = (companyId: string): AppThunk => async (dispatch): Promise<void> => {
    const phases = await api.getCompanyLoanPhases({ companyId });
    if (Array.isArray(phases)) {
        dispatch(loanPhaseSlice.actions.setLoanStages(sortByDisplayOrder(phases)));
    }
};

export const getCurrentLenderPhases = (): AppThunkPromise => async (dispatch, getState): Promise<void> => {
    const { view: { employer } } = getState();
    if (employer) {
        dispatch(getLoanStagesByCompanyId(employer.id));
    }
}

export const loanStagesSelector = createSelector((state: RootState) => state.loanStages.phases, phases => sortPhasesByDisplayOrder(Object.keys(phases).map((key) => phases[key])));
export const originationLoanStagesSelector = createSelector((state: RootState) => loanStagesSelector(state), phases => phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.ORIGINATION));
export const leadLoanStagesSelector = createSelector((state: RootState) => loanStagesSelector(state), phases => phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.LEAD));
export const archiveLoanStagesSelector = createSelector((state: RootState) => loanStagesSelector(state), phases => phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.ARCHIVE));
export const portfolioLoanStagesSelector = createSelector((state: RootState) => loanStagesSelector(state), phases => phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.PORTFOLIO));

export default loanPhaseSlice;
