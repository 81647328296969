import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { LoanDto, LoanPhaseCategoryType, NewLoanDTO, UpdateLoanDTO } from 'src/backend';
import { DEFAULT_LOANS_PER_PAGE } from 'src/constants/loan';
import { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { LoansRequest } from 'src/types/loan';
import { transformLoan } from 'src/utils/loan/transform-loan';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

import loanSlice from './loan';
import loanPhaseSlice from './loan-stages';
import { handleRequestError, toggleConnectionError } from './ui';


interface LoanManagerState {
    // loanId -> loan
    loans: Record<string, LoanDto>;
    // phaseId -> page -> loanId[]
    phasePages: Record<string, Record<number, string[]>>;
    // phaseId -> boolean
    phaseLoading: Record<string, boolean>;
    // phaseId -> count
    phaseLoansCount: Record<string, number>;
    // phase category -> count
    loansCountByPhaseCategory: Record<LoanPhaseCategoryType, boolean>;
}

const initialState: LoanManagerState = {
    loans: {},
    phasePages: {},
    phaseLoading: {},
    phaseLoansCount: {},
    loansCountByPhaseCategory: {
        [LoanPhaseCategoryType.ARCHIVE]: false,
        [LoanPhaseCategoryType.ORIGINATION]: false,
        [LoanPhaseCategoryType.LEAD]: false,
        [LoanPhaseCategoryType.PORTFOLIO]: false,
    },
};

export const loansManagerSlice = createSlice({
    name: 'loan-manager',
    initialState,
    reducers: {
        appendLoans(state: LoanManagerState, action: PayloadAction<{ loans: LoanDto[], page: number, phaseId: string }>): void {
            // update current state or add new loans
            action.payload.loans.forEach(loan => {
                if (!isEqual(state.loans[loan.id], loan)) {
                    state.loans = {
                        ...state.loans,
                        [loan.id]: {
                            ...state.loans[loan.id],
                            ...loan,
                            userProgress: !!loan.userProgress ? loan.userProgress : {},
                        }
                    };
                }
            });
        },
        prependLoans(state: LoanManagerState, action: PayloadAction<{ loans: LoanDto[], page: number, phaseId: string }>): void {
            // update current state or add new loans
            action.payload.loans.forEach(loan => {
                if (!isEqual(state.loans[loan.id], loan)) {
                    state.loans = {
                        [loan.id]: {
                            ...state.loans[loan.id],
                            ...loan,
                            userProgress: !!loan.userProgress ? loan.userProgress : {},
                        },
                        ...state.loans
                    };
                }
            });
        },
        setPhaseLoading: (state, action: PayloadAction<{ phase: string, loading: boolean }>) => {
            state.phaseLoading[action.payload.phase] = action.payload.loading;
        },
        setPhasePageLoans: (state, action: PayloadAction<{ phaseId: string, page: number, loanIds: string[] }>) => {
            if (!state.phasePages[action.payload.phaseId]) {
                state.phasePages[action.payload.phaseId] = {};
            }
            state.phasePages[action.payload.phaseId][action.payload.page] = action.payload.loanIds;
        },
        setPhaseCategoryHasLoans: (state, action: PayloadAction<{ phaseCategory: LoanPhaseCategoryType, hasLoans: boolean }>) => {
            if (action.payload.hasLoans) {
                state.loansCountByPhaseCategory[action.payload.phaseCategory] = action.payload.hasLoans;
            }
        },
        setPhaseLoansCount: (state, action: PayloadAction<{ phaseId: string, count: number }>) => {
            state.phaseLoansCount[action.payload.phaseId] = action.payload.count;
        },
        toggleLoanPin: (state, action: PayloadAction<{ loanId: string, pinned: boolean }>) => {
            state.loans[action.payload.loanId].pinned = action.payload.pinned;
        },
        setLoans: (state, action: PayloadAction<Record<string, LoanDto>>) => {
            Object.keys(action.payload).forEach((key) => {
                state.loans[key] = action.payload[key];
            });
        },
        removeLoan(state, action: PayloadAction<string>): void {
            delete state.loans[action.payload];
        },
    }
});

export const createV2Loan = (loan: NewLoanDTO): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    const result = await api.createV2Loan(loan);
    dispatch(actions.prependLoans({ loans: [result], page: 1, phaseId: result.loanPhase?.id }));
    return result;
}

export const updateV2Loan = (id: string, loan: UpdateLoanDTO): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    const result = await api.updateV2Loan(id, loan);
    dispatch(actions.appendLoans({ loans: [result], page: 1, phaseId: result.loanPhase?.id }));
    return result;
}

const getIsRequestPending = (params: LoansRequest, state: RootState): boolean => {
    const { [loansManagerSlice.name]: { phaseLoading } } = state;
    // get if we have already loaded this page
    const pageLoaded = !!state[loansManagerSlice.name].phasePages?.[params?.phaseId]?.[params?.page];
    // get if we are currently loading this phase
    const phaseLoadingNow = !!phaseLoading?.[params?.phaseId];

    if (params.page > 1) {
        // if the page is already loaded
        // we don't need to load it again
        return pageLoaded;
    }


    // if we are on page 1 and the phase is loading
    // we don't need to load it again
    return phaseLoadingNow;
}

export const checkLoanCategoryHasLoans = (phaseCategory: LoanPhaseCategoryType): AppThunk => async (dispatch, getState): Promise<void> => {
    const loans = await api.getLoans({ phaseCategory, size: 1, page: 1 });
    // we are on the first page
    // we are going to set if the phase category has loans 
    dispatch(actions.setPhaseCategoryHasLoans({
        phaseCategory,
        hasLoans: loans.totalElements > 0,
    }));
}

export const getLoans = (params?: LoansRequest): AppThunkPromise<{ lastPage: boolean, loans: LoanDto[] }> => async (dispatch, getState): Promise<{ lastPage: boolean, loans: LoanDto[] }> => {
    const state = getState();
    if (!getIsRequestPending(params, state)) {
        const { ui: { connectionError } } = state;
        try {
            dispatch(loansManagerSlice.actions.setPhaseLoading({ phase: params?.phaseId, loading: true }));
            const sanitizeHtml = (await import('sanitize-html')).default;
            const getLoansParams = {
                size: DEFAULT_LOANS_PER_PAGE,
            }
            if (params?.phaseId) {
                getLoansParams['phaseId'] = params.phaseId;
            }
            if (params?.phaseCategory) {
                getLoansParams['phaseCategory'] = params.phaseCategory;
            }
            if (params?.page) {
                getLoansParams['page'] = params.page;
            }
            if (params?.size) {
                getLoansParams['size'] = params.size;
            }
            const data = await api.getLoans(getLoansParams);

            if (params?.phaseId) {
                dispatch(loansManagerSlice.actions.setPhaseLoansCount({
                    phaseId: params.phaseId,
                    count: data?.totalElements,
                }));
            }
            if (data?.content.length > 0) {
                dispatch(loanSlice.actions.setPrimaryLenderName(data.content[0]?.lender?.name));
            }
            // if data is an array
            if (Array.isArray(data.content)) {
                const sanitizedLoans = data.content.map(loan => {
                    const updatedLoan = { ...loan };
                    updatedLoan.loanPurpose = sanitizeHtml(loan.loanPurpose);
                    return updatedLoan;
                });
                dispatch(loansManagerSlice.actions.appendLoans({
                    loans: sanitizedLoans,
                    page: params.page,
                    phaseId: params?.phaseId,
                }))
                if (data.content.length) {
                    dispatch(loansManagerSlice.actions.setPhasePageLoans({
                        phaseId: params?.phaseId,
                        page: params.page,
                        loanIds: sanitizedLoans.map(loan => loan.id),
                    }));
                }
            }
            if (connectionError) {
                dispatch(toggleConnectionError(false));
            }
            return {
                lastPage: data.last,
                loans: data.content,
            };
        } catch (error) {
            notifyBugTracker(error);
            dispatch(handleRequestError(error));
            return {
                lastPage: true,
                loans: [],
            };
        } finally {
            dispatch(loansManagerSlice.actions.setPhaseLoading({ phase: params?.phaseId, loading: false }));
        }
    } else {
        return {
            lastPage: true,
            loans: [],
        };
    }
}

export const loansSelector = createSelector([
    (state: RootState) => state[loansManagerSlice.name].loans
], (loans) => {
    return Object.values(loans).map(loan => {
        return transformLoan(loan)
    });
});

export const isPhaseLoansLoadingSelector = (phaseId: string) => createSelector([
    (state: RootState) => state[loansManagerSlice.name].phaseLoading[phaseId]
], (loading) => {
    return loading;
}
);

export const loanPhaseMaxPageSelector = (phaseId: string) => createSelector([
    (state: RootState) => state[loansManagerSlice.name].phasePages[phaseId]
], (pages) => {
    return pages ? Object.keys(pages).length : 0;
}
);

export const loanPhaseLoansCountSelector = (phaseId: string) => createSelector([
    (state: RootState) => state[loansManagerSlice.name].phaseLoansCount[phaseId]
], (count) => {
    return count ?? 0;
}
);

export const selectLoansCountByPhaseCategory = createSelector([(state: RootState) => state[loansManagerSlice.name].loansCountByPhaseCategory], (loansCountByPhaseCategory) => {
    return loansCountByPhaseCategory;
});

export const { reducer, actions } = loansManagerSlice;
