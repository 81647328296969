import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'
import { FormElementV2ResponseDto } from 'src/backend';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

import type { AppThunkPromise, RootState } from '../store';
import { answerFileFormElement } from './form-element';
import { licenseKeysSlice } from './license-keys';
import { checkFileForFieldTasks } from './task';

interface DocumentsState {
    uploadingIds: Record<string, string>,
    formElementUploadingProgress: Record<string, number>,
    uploadedIds: Record<string, string>,
}

export const documentSliceInitialSlice: DocumentsState = {
    formElementUploadingProgress: {},
    uploadingIds: {},
    uploadedIds: {}
};

const slice = createSlice({
    name: 'documents',
    initialState: documentSliceInitialSlice,
    reducers: {
        addIdToUploadingIds(state: DocumentsState, action: PayloadAction<string>): void {
            state.uploadingIds[action.payload] = action.payload;
        },
        removeIdToUploadingIds(state: DocumentsState, action: PayloadAction<string>): void {
            delete state.uploadingIds[action.payload];
        },
        addIdToUploadedIds(state: DocumentsState, action: PayloadAction<string>): void {
            state.uploadedIds[action.payload] = action.payload;
        },
        setUploadingProgress(state: DocumentsState, action: PayloadAction<{ id: string, progress: number }>): void {
            state.formElementUploadingProgress[action.payload.id] = action.payload.progress;
        },
        removeFormElementUploadingProgress(state: DocumentsState, action: PayloadAction<string>): void {
            delete state.formElementUploadingProgress[action.payload];
        }
    }
});

export const { reducer } = slice;

export const uploadDocument = ({ file, formElement, type, loanId }: { file: File, formElement: FormElementV2ResponseDto, type: 'FormElement'; loanId: string }): AppThunkPromise => async (dispatch, getState): Promise<string> => {
    try {
        const { [licenseKeysSlice.name]: { pdftronKey } } = getState();
        dispatch(slice.actions.addIdToUploadingIds(formElement.id));
        const uploadResult = await uploadFileToDocumentId({
            file,
            pdfTronKey: pdftronKey,
            loanId,
            formElementId: formElement.id,
            name: formElement.title,
            progress: (percent) => {
                dispatch(setFormElementUploadingProgress(formElement.id, percent));
            }
        });
        dispatch(slice.actions.addIdToUploadedIds(formElement.id));

        if (type === 'FormElement') {
            await dispatch(answerFileFormElement({
                formElementId: formElement.id,
                documentId: uploadResult.documentId,
                loanId
            }));
            if (getExtensionFromFilename(file.name) === 'pdf') {
                dispatch(checkFileForFieldTasks({
                    formElementId: formElement.id,
                    loanId,
                    file: uploadResult.file,
                }));
            }
        }

        return uploadResult.documentId;
    } catch (err) {
        notifyBugTracker(err);
        console.error('Error uploading document', err);
    } finally {
        dispatch(removeFormElementUploadingProgress(formElement?.id));
        dispatch(slice.actions.removeIdToUploadingIds(formElement?.id));
    }
};

// add id to uploadingIds
export const addIdToUploadingIds = (id: string): PayloadAction<string> => slice.actions.addIdToUploadingIds(id);
// remove id from uploadingIds
export const removeIdFromUploadingIds = (id: string): PayloadAction<string> => slice.actions.removeIdToUploadingIds(id);

export const selectIdIsUploading = (id: string) => createSelector(
    (state: RootState) => state.documents.uploadingIds,
    (uploadingIds) => uploadingIds[id] !== undefined
);

export const setFormElementUploadingProgress = (id: string, progress: number): PayloadAction<{ id: string, progress: number }> => slice.actions.setUploadingProgress({ id, progress });

export const removeFormElementUploadingProgress = (id: string): PayloadAction<string> => slice.actions.removeFormElementUploadingProgress(id);

export const selectIdIsUploaded = (id: string) => createSelector(
    (state: RootState) => state.documents.uploadedIds,
    (uploadedIds) => uploadedIds[id] !== undefined
);

export const selectFormElementUploadingProgress = (ids: string[]) => createSelector(
    (state: RootState) => state.documents.formElementUploadingProgress,
    (formElementUploadingProgress) => {
        const uploadingIds = ids
            .filter(id => typeof formElementUploadingProgress[id] !== 'undefined')
        const progress = uploadingIds
            .reduce((acc: number, id: string) => acc += formElementUploadingProgress[id], 0) / uploadingIds.length

        if (isNaN(progress)) {
            return 0;
        }

        return progress;
    }
);




export default slice;
