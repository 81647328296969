import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { LENDER_VIEW } from 'src/constants/person';
import { FieldTaskType } from 'src/constants/task';
import { EMPTY_OBJECT, TaskGroup } from 'src/constants/ui';
import { getString } from 'src/i18n/labels';
import { ValueOf } from 'src/types/common';
import { Loan } from 'src/types/loan';
import { FieldTask, Task } from 'src/types/tasks';
import { getBlobAndFileNameFromUrl } from 'src/utils';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { getFileFieldTasks } from 'src/utils/get-file-field-tasks';
import { isIdABorrowerOnLoanRole } from 'src/utils/loan/is-id-borrower-on-loan-role';

import { AppUserDTO2, FormElementPackageInfoResponseDto, FormElementV2ResponseDto, ITask, Role } from "../backend";
import type { AppThunk, AppThunkPromise, RootState } from '../store';
import { formElementSlice } from './form-element';
import { licenseKeysSlice } from './license-keys';
import { loansManagerSlice } from './loan-manager';

export type UserTasks = Record<string, Record<string, Task[]>>;
// key is user id  and value is a record of task group and count
export type UserTasksCount = Record<string, Record<string, number>>;

const getTaskTitleByTaskGroup = (taskGroup: ValueOf<typeof TaskGroup>): string => {
    switch (taskGroup) {
        case TaskGroup.AssignSigneeTask:
            return getString('assignSignee');
        case TaskGroup.FillTask:
            return getString('fillAndSign');
        case TaskGroup.ShoeBoxTask:
            return getString('shoeBox');
        case TaskGroup.ApproveAnswerTask:
            return getString('accept');
        case TaskGroup.AnswerQuestionTask:
            return getString('upload');
    }
    return taskGroup;
}
interface TaskState {
    postTasksPending: boolean;
    getTasksPending: Record<string, boolean>;
    loanTasks: Record<string, UserTasks>;
    loanTasksCount: Record<string, UserTasksCount>;
    expandedAccordionUserId: string;
}

const initialState: TaskState = {
    getTasksPending: {},
    postTasksPending: false,
    loanTasks: {},
    loanTasksCount: {},
    expandedAccordionUserId: null,
};

const slice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setLoanUserTasks(
            state: TaskState,
            action: PayloadAction<{ tasks: Record<string, Task[]>, loanId: string, userId: string }>
        ): void {
            if (!isEqual(state.loanTasks[action.payload.loanId]?.[action.payload.userId], action.payload.tasks)) {
                state.loanTasks[action.payload.loanId] = {
                    ...state.loanTasks[action.payload.loanId],
                    [action.payload.userId]: action.payload.tasks
                };
            }
        },
        setLoanUserTasksCount(
            state: TaskState,
            action: PayloadAction<{ taskGroupCount: Record<string, number>, loanId: string, userId: string }>
        ): void {
            if (!isEqual(state.loanTasksCount[action.payload.loanId]?.[action.payload.userId], action.payload.taskGroupCount)) {
                state.loanTasksCount[action.payload.loanId] = {
                    ...state.loanTasksCount[action.payload.loanId],
                    [action.payload.userId]: action.payload.taskGroupCount
                };
            }
        },
        setExpandedAccordionUserId(state: TaskState, action: PayloadAction<string>): void {
            state.expandedAccordionUserId = action.payload;
        },
        setPostTasksPending(state: TaskState, action: PayloadAction<boolean>): void {
            state.postTasksPending = action.payload;
        },
        setGetTasksPending(state: TaskState, action: PayloadAction<{ userId: string, pending: boolean }>): void {
            state.getTasksPending[action.payload.userId] = action.payload.pending;
        }
    }
});

export const { reducer, actions } = slice;

export const setExpandedAccordionUserId = (userId: string): AppThunk => async (dispatch) => {
    dispatch(actions.setExpandedAccordionUserId(userId));
}

export const transformLoanUserTasks = ({
    loanId,
    userId,
    tasks,
    formElementPackageInfos,
    formElements
}: {
    loanId: string;
    userId: string;
    tasks: ITask[];
    formElementPackageInfos: FormElementPackageInfoResponseDto[];
    formElements: FormElementV2ResponseDto[];
}): AppThunk => async (dispatch, getState): Promise<void> => {
    try {

        const tasksWithPackageInfo = tasks?.reduce((all, nextTask) => {
            const packageInfo = formElementPackageInfos.find(packageInfo => nextTask.contextId === packageInfo.id);
            return ({
                ...all,
                [nextTask.taskType]: [
                    ...all[nextTask.taskType] || [],
                    ({
                        ...nextTask,
                        formElement: packageInfo,
                    })
                ]
            });
        }, {});
        dispatch(slice.actions.setLoanUserTasks({ tasks: tasksWithPackageInfo, loanId, userId }));
    } catch (error) { }
}


export const getAllLoanUsersTasks = (loanId: string): AppThunk => async (dispatch, getState): Promise<void> => {
    const { [loansManagerSlice.name]: { loans }, view: { viewType, user }, [formElementSlice.name]: {
        packageInfo,
        formElements
    } } = getState();
    try {
        const consolidatedTasks = await api.consolidatedTasks(loanId);
        const isUserALender = viewType === LENDER_VIEW;
        const loan: Loan = loans[loanId];
        const isUserLeadBorrowerOnLoan = loan?.loanRoles.some(role => role.role === Role.LEAD_BORROWER && role.user.id === user.id);
        if (loan) {
            const pollTasksForUsers: AppUserDTO2[] = [];
            const pollTasksCountForUsers = []
            if (isUserALender) {
                pollTasksForUsers.push(...loan.loanRoles
                    .map(role => role.user));
                pollTasksCountForUsers.push(
                    ...pollTasksForUsers
                );
            } else if (isUserLeadBorrowerOnLoan) {
                pollTasksForUsers.push(...loan.loanRoles
                    .filter(role => isIdABorrowerOnLoanRole(role.user.id, role))
                    .map(role => role.user));
                pollTasksCountForUsers.push(
                    ...pollTasksForUsers
                );
            } else {
                pollTasksForUsers.push(...loan.loanRoles
                    .filter(role => role.user.id === user.id)
                    .map(role => role.user));
                pollTasksCountForUsers.push(...loan.loanRoles
                    .filter(role => isIdABorrowerOnLoanRole(role.user.id, role))
                    .map(role => role.user))
            }
            for (const user of pollTasksForUsers) {
                const userTaskDetails = consolidatedTasks.perUser[user.id];
                if (typeof userTaskDetails !== 'undefined') {
                    dispatch(transformLoanUserTasks({
                        tasks: userTaskDetails.forMe,
                        loanId,
                        userId: user.id,
                        formElementPackageInfos: Object.values(packageInfo[loanId]),
                        formElements: Object.values(formElements[loanId]),
                    }));
                }
            };

            for (const user of pollTasksCountForUsers) {
                const userTaskDetails = consolidatedTasks.perUser[user.id];
                if (typeof userTaskDetails !== 'undefined') {
                    dispatch(slice.actions.setLoanUserTasksCount({ taskGroupCount: userTaskDetails.count, loanId, userId: user.id }));
                }
            }
        }
    } catch (error) {
        console.error('error getting tasks', error);
    }
}

export const checkFormElementIdForFieldTasks = ({ loanId, formElementId }: { loanId: string, formElementId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    const { view: { user: { id } }, formElement: { formElements } } = getState();
    const loanFormElements: FormElementV2ResponseDto[] = Object.values(formElements[loanId]);
    const formElement = loanFormElements.find((formElement: FormElementV2ResponseDto) => formElement.id === formElementId);
    if (!formElement?.answer?.document?.id) {
        return;
    }
    dispatch(checkFormElementForFieldTasks({
        loanId,
        formElement,
        loggedInUserId: id
    }));
};

export const checkFormElementForFieldTasks = ({ loanId, formElement, loggedInUserId }: { loanId: string, formElement: FormElementV2ResponseDto, loggedInUserId: string }): AppThunkPromise => async (dispatch, getState): Promise<void> => {
    const {[licenseKeysSlice.name]: {
        syncfusionKey
    }} = getState();
    if (!formElement.approved) {
        try {
            const downloadUrl = await api.getDocumentDownloadUrl(formElement.answer.document.id);
            if (!downloadUrl) {
                return;
            }
            const { blob, filename } = await getBlobAndFileNameFromUrl(downloadUrl);
            if (getExtensionFromFilename(filename) === 'pdf') {
                const tasks = await getFileFieldTasks(blob, loggedInUserId, syncfusionKey);
                dispatch(postFormElementTask({
                    loanId,
                    formElementId: formElement.id,
                    tasks
                }));
            } else {
                await api.postTasksForV2Element(formElement.id, []);
            }
        } catch (error) {
        }
    }
}

export const checkFileForFieldTasks = ({ loanId, formElementId, file }: { loanId: string, formElementId: string, file: File }): AppThunk => async (dispatch, getState): Promise<void> => {
    const { view: { user: { id } },[licenseKeysSlice.name]: {
        syncfusionKey
    } } = getState();
    const tasks = await getFileFieldTasks(file, id, syncfusionKey);
    dispatch(postFormElementTask({
        loanId,
        formElementId,
        tasks
    }));
};

export const postFormElementTask = ({ loanId, formElementId, tasks }: { loanId: string, formElementId: string, tasks: FieldTask[] }): AppThunk => async (dispatch, getState): Promise<void> => {
    const { task: { postTasksPending }, [loansManagerSlice.name]: { loans } } = getState();
    if (postTasksPending) {
        return;
    }
    const loan: Loan = loans[loanId];
    const loanFormElements = await api.getV2FormElements({ loanId });

    const finalTasks = [];
    const formElement = Object.values(loanFormElements).find(formElement => formElement.id === formElementId);
    const leadLenderUser = loan.loanRoles.find(loanRole => loanRole.role === Role.LEAD_LENDER)?.user;
    dispatch(actions.setPostTasksPending(true));

    tasks.forEach(task => {
        if ((
            task.type === FieldTaskType.FILL &&
            finalTasks.findIndex(t => t.type === FieldTaskType.FILL) === -1
        ) ||
            task.type === FieldTaskType.SIGN
            || task.type === FieldTaskType.ASSIGN_SIGNEE) {
            let userId = task.userId;
            if (task.type === FieldTaskType.ASSIGN_SIGNEE && leadLenderUser?.id) {
                userId = leadLenderUser.id;
            } else if (task.type === FieldTaskType.SIGN) {
                userId = task.id;
            } else if (!!formElement?.assignedToUser?.id) {
                userId = formElement.assignedToUser.id;
            }
            if (!!userId) {
                finalTasks.push({
                    targetId: userId,
                    type: task.type,
                    fieldId: task.fieldId,
                });
            }
        }
    });
    try {
        await api.postTasksForV2Element(formElementId, finalTasks);
        dispatch(getAllLoanUsersTasks(loanId));
    } catch (error) {
        console.error('error posting tasks', error);
    } finally {
        dispatch(actions.setPostTasksPending(false));
    }
}

export const processAllLoanAnsweredFormElementsForTasks = (loanId: string): AppThunk => async (dispatch, getState): Promise<void> => {
    try {
        const { view: { user: { id } }, task: { loanTasks } } = getState();
        const loanUsersTasks = loanTasks[loanId] ? Object.values(loanTasks[loanId]) : [];

        const allUsersTasks: string[] = loanUsersTasks.reduce((all: string[], next: any) => {
            const userTasks = Object.values(next);
            let ids = [];
            if (Array.isArray(userTasks)) {
                ids = [...userTasks.reduce((all: any[], next: any) => {
                    return [...all, ...next.filter((task: Task) => ([TaskGroup.AssignSigneeTask, TaskGroup.FillTask, TaskGroup.SignTask] as string[]).includes(task.taskType)).map((t: any) => t.contextId)]
                }, [])];
            }
            return [...all, ... new Set(ids)];
        }, []);
        const formElements = await api.getV2FormElements({ loanId });
        // find all answered form elements recursively
        const answeredFormElements = Object.values(formElements).filter(f => !!f.answer?.document?.id).filter(f => allUsersTasks.indexOf(f.id) === -1);
        for (const formElement of answeredFormElements) {
            await dispatch(checkFormElementForFieldTasks({ loanId, formElement, loggedInUserId: id }));
        };
    } catch (error) {
    }
}

// generate UUID from string
export const generateUUID = (str: string): string => {
    str = str.replace('-', '');
    return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c, p) {
        return str[p % str.length];
    });
}

export const userTasksSelector = ({ userId, loanId }: { userId: string, loanId: string }) => createSelector((state: any) => state.task.loanTasks, loanTasks => {
    return loanTasks?.[loanId]?.[userId] ?? [];
})

export const loanTasksSelector = ({ loanId }: { loanId: string }) => createSelector<[(state: RootState) => any, (state: RootState) => any], Record<string, UserTasks[]>>([
    (state: RootState) => state.task.loanTasks,
    (state: RootState) => selectPinnedAcceptTask(state),
],
    (loanTasks: Record<string, Record<string, UserTasks[]>>, data: { index: number, task: Task }) => {

        const tasks = loanTasks[loanId] ?? EMPTY_OBJECT;
        const transformedTasks = Object.keys(tasks).reduce((acc, userId) => {
            let updatedAcceptTaskGroup = tasks[userId][TaskGroup.ApproveAnswerTask];
            let updatedAnswerTaskGroup = tasks[userId][TaskGroup.AnswerQuestionTask];
            // if user has accept tasks and there is a not pinned accept task in the group
            if (!!tasks[userId][TaskGroup.ApproveAnswerTask] &&
                !!data &&
                !tasks[userId][TaskGroup.ApproveAnswerTask].find((t: Task) => t.contextId === data.task.contextId)) {
                // add the task at the specific index 
                updatedAcceptTaskGroup = [
                    ...tasks[userId][TaskGroup.ApproveAnswerTask].slice(0, data.index),
                    data.task,
                    ...tasks[userId][TaskGroup.ApproveAnswerTask].slice(data.index)
                ];
            }
            if (!!tasks[userId][TaskGroup.AnswerQuestionTask] &&
                !!data &&
                !tasks[userId][TaskGroup.AnswerQuestionTask].find((t: Task) => t.contextId === data.task.contextId)) {
                // add the task at the specific index 
                updatedAnswerTaskGroup = [
                    ...tasks[userId][TaskGroup.AnswerQuestionTask].slice(0, data.index),
                    data.task,
                    ...tasks[userId][TaskGroup.AnswerQuestionTask].slice(data.index)
                ];
            }
            return {
                ...acc,
                [userId]: {
                    ...tasks[userId],
                    ...!!updatedAcceptTaskGroup && { [TaskGroup.ApproveAnswerTask]: updatedAcceptTaskGroup },
                    ...!!updatedAnswerTaskGroup && { [TaskGroup.AnswerQuestionTask]: updatedAnswerTaskGroup },
                }
            }
        }, {});
        return transformedTasks;
    }
);

export const userTasksCountByType = createSelector(
    [
        (state: RootState) => state.task.loanTasksCount,
        (state: RootState) => state.view.currentLoanId
    ],
    (loanTasks, currentLoanId) => {
        const tasksCountByType = {};
        Object.keys(loanTasks).filter(loanId => loanId === currentLoanId).forEach(loanId => {
            Object.keys(loanTasks[loanId]).forEach(userId => {
                tasksCountByType[userId] = {};
                Object.keys(loanTasks[loanId][userId]).forEach(taskGroup => {
                    tasksCountByType[userId][taskGroup] = {
                        count: loanTasks[loanId][userId][taskGroup],
                        title: getTaskTitleByTaskGroup(taskGroup as any)
                    };
                });
            });
        });
        return tasksCountByType;
    }
)

export const selectPinnedAcceptTask = createSelector([
    (state: RootState) => state[formElementSlice.name].pinnedTask],
    (data: { index: number, task: Task }) => {
        if (!data) {
            return null;
        }

        return {
            index: data.index,
            task: data.task,
        };

    });