import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export interface GenericToast {
    id: string;
    title: string;
    hint: string;
    status: "LOADING" | "FAILURE" | "SUCCESS";
    abortController?: AbortController;
}

export const toastsAdapter = createEntityAdapter<GenericToast>({
    selectId: (toast) => toast.id,
    sortComparer: (a, b) => String(a.id).localeCompare(b.id),
});

export const cancelToast = (toast: GenericToast) => {
    if (toast.abortController) {
        try {
            toast.abortController.abort("Canceled by user");
        } catch (error) {
            //ignore
        }
    }
}

export const toastSlice = createSlice({
    name: 'ui/app-toasts',
    initialState: toastsAdapter.getInitialState(),
    reducers: {
        toastAdded: toastsAdapter.addOne,
        toastRemoved: toastsAdapter.removeOne,
        toastUpdated: toastsAdapter.updateOne,
    },
});


export const toastSelectors = toastsAdapter.getSelectors((state: RootState) => state[toastSlice.name]);