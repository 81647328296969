export const fileFormElementReplies = (title: string) => [
    `Regarding the ${title}`,
    `This doesn't seem to be the right {{period}}, we need the {{newPeriod}}  ${title}`,
    `TBD from Joshua`,
    `I couldn't read this file, could you send as a PDF`
];

export const sectionFormElementReplies = [
    'This section needs to be done ASAP',
    'The principal in charge of this section is on vacation'

];

export const personReplies = [
    'Alice perkins will not be available next week',
    'Can you send a copy of the file to alice perkins',
];

export const noContextReplies = [
    `Do you think this loan is worth it?`,
    `I need to know more about the loan`,
];
