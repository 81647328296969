// list of http status codes
export const HTTP_STATUS = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504
} as const;

export const SYNCFUSION_SAVE_URL = 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save'
export const SYNCFUSION_OPEN_URL = 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open'