export const AutoFillAnnotationType = {
    TextAnnotation: 'TextAnnotation',
    SignatureAnnotation: 'SignatureAnnotation',
    LinkAnnotation: 'LinkAnnotation',
} as const;

export type TextAnnotation = {
    id: string;
    source: string;
    type: keyof typeof AutoFillAnnotationType;
};

export type SignatureAnnotation = {
    id: string;
    type: 'SignatureAnnotation',
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    pageNumber: number,
}

export type LinkAnnotation = {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    label: string,
    source: string,
    pageNumber: number;
    type: 'LinkAnnotation'
};

export type AutoFillAnnotations = TextAnnotation | LinkAnnotation | SignatureAnnotation;

export const rockCanyonAnnotations: AutoFillAnnotations[] = [
    {
        id: 'Applicant Name',
        source: 'assignee.person.givenName assignee.person.familyName',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant DOB',
        source: 'assignee.person.birthDate',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant Driver\'s License Number',
        source: 'assignee.person.usDriversLicense.number',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant Driver\'s License Exp Date',
        source: 'assignee.person.usDriversLicense.expiration',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant Social Security Number',
        source: 'assignee.person.socialSecurityNumber',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant Present Address',
        source: 'assignee.person.homeAddress.streetAddressLine1 assignee.person.homeAddress.citySuburb assignee.person.homeAddress.stateProvinceRegion assignee.person.homeAddress.postalCode',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        id: 'Applicant Signature Date_af_date',
        type: AutoFillAnnotationType.SignatureAnnotation,
        x1: 118.76,
        y1: 178.78999999999996,
        x2: 285.81,
        y2: 198.37,
        pageNumber: 1,
    },
    {
        id: 'Joint Applicant Signature Date_af_date',
        type: AutoFillAnnotationType.SignatureAnnotation,
        x1: 388.76,
        y1: 178.78999999999996,
        x2: 555.81,
        y2: 198.37,
        pageNumber: 1,
    },
    {
        id: 'creditScoreField',
        source: 'assignee.person.softCreditPull.creditScore',
        type: AutoFillAnnotationType.TextAnnotation,
    },
    {
        x1: 40,
        x2: 95,
        y1: 234,
        y2: 246,
        label: 'See here',
        source: 'assignee.formElement.Schedule of Existing Debt',
        type: AutoFillAnnotationType.LinkAnnotation,
        pageNumber: 5
    }
];