import { api } from "src/api";

import { getFileExtension } from "../get-file-extension";
import { processFileForUpload } from "../process-file-for-upload";

export const uploadFileToDocumentId = async (args: { loanId?: string, formElementId?: string, file: File, name: string, pdfTronKey: string, progress?: (percent: number) => void }): Promise<{
    documentId: string;
    file: File;
}> => {
    const fileExtension = getFileExtension(args.file);
    const finalFile = await processFileForUpload(args.file, args.pdfTronKey);

    if (fileExtension === 'heic') {
        const uploadedDocument = await api.uploadDocument({
            file: args.file,
            name: args.name,
            loanId: args.loanId,
            formElementId: args.formElementId,
            onUploadProgress: args.progress
        });
        const convertedDocument = await api.convertImage({ documentId: uploadedDocument.id });
        // @ts-expect-error
        const documentId = convertedDocument.newDocumentId as string;

        return {
            documentId,
            file: finalFile
        }
    } else {
        const document = await api.uploadDocument({
            file: finalFile,
            name: args.name,
            loanId: args.loanId,
            formElementId: args.formElementId,
            onUploadProgress: args.progress
        });
        return {
            documentId: document.id,
            file: finalFile
        };
    }
};