import { api } from "src/api";

import { getFileFromUrl } from "./url/get-file-from-url";

// convert HEIC to JPG
export const convertHeicToJpg = async (file: File): Promise<File> => {
    if (!file.name.toLowerCase().endsWith(".heic")) {
        return (file);
    }
    try {
        const document = await api.uploadDocument({ file, name: file.name });
        const convertedDocument = await api.convertImage({ documentId: document.id });
        // @ts-expect-error
        const convertedDocumentId = convertedDocument.newDocumentId as string;
        const documentDownloadUrl = await api.getDocumentDownloadUrl(convertedDocumentId);
        const convertedFile = await getFileFromUrl(documentDownloadUrl);
        return (new File(
            [convertedFile],
            file.name.toLowerCase().replace('heic', 'jpg'),
            { type: 'image/jpeg' }));
    } catch (err) {
        return (file);
    }
}