import type { Action, PreloadedState } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { StateFromReducersMapObject } from "redux";
import type { ThunkAction } from 'redux-thunk';
import { learningCenterApi } from 'src/services/learningCenterApi';
import { reducers, rootReducer } from 'src/store/root-reducer';

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'formElement/setZipPasswordCallback',
          'ui/setDadZipFileHandlingFiles',
          'ui/setZipFileActionCallback',
          'ui/setDadZipFileHandling',
          'ui/app-toasts/toastAdded'
        ],
        // Ignore these paths in the state
        ignoredPaths: ['formElement.zipPasswordCallback', 'ui.zipFileActionCallback', 'ui/app-toasts.entities'],
      },
    }).concat(learningCenterApi.middleware),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore these action types
          ignoredActions: [
            'formElement/setZipPasswordCallback',
            'ui/setDadZipFileHandlingFiles',
            'ui/setZipFileActionCallback',
            'ui/setDadZipFileHandling',
            'ui/app-toasts/toastAdded'
          ],
          // Ignore these paths in the state
          ignoredPaths: ['formElement.zipPasswordCallback', 'ui.zipFileActionCallback', 'ui/app-toasts.entities'],
        },
      }),
  })
}

export type RootState = StateFromReducersMapObject<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<T = any> = ThunkAction<T, RootState, null, Action<string>>;

export type AppThunkPromise<T = any> = ThunkAction<Promise<T>, RootState, null, Action<string>>;

export type AppStore = ReturnType<typeof setupStore>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
