import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { IndividualResponseDto, PersonResponseDto, PersonUpdateDto } from 'src/backend';
import { AppThunkPromise, RootState } from 'src/store';


interface TemplateTypeV2State {
    individuals: Record<string, IndividualResponseDto[]>
    individualPerson: Record<string, PersonUpdateDto>
}

const initialState: TemplateTypeV2State = {
    individuals: {},
    individualPerson: {}
};

export const individualSlice = createSlice({
    name: 'individual',
    initialState,
    reducers: {
        setIndividuals: (state: TemplateTypeV2State, action: PayloadAction<IndividualResponseDto[]>) => {
            state.individuals = action.payload.reduce((acc, individual) => {
                const { id, ...rest } = individual;
                acc[id] = rest;
                return acc;
            }, {});
        },
        setIndividualPerson: (state: TemplateTypeV2State, action: PayloadAction<{ person: PersonUpdateDto, id: string }>) => {
            state.individualPerson[action.payload.id] = action.payload.person;
        }
    }
});

export const getPersonFromIndividual = (id: string): AppThunkPromise<PersonResponseDto> => async (dispatch: any): Promise<PersonResponseDto> => {
    const person = await api.getPersonFromIndividual(id, { id });
    dispatch(actions.setIndividualPerson({
        id, person: {
            ...person,
            doesNeedOnboarding: true,
            companyAddress: null,
            companyName: null,
            companyPhone: null,
            companyWebsite: null,
            positionOrTitle: null,
            maritalStatus: null,
        }
    }));
    return person;
}

export const getIndividualFromPerson = (id: string): AppThunkPromise<IndividualResponseDto> => async (dispatch: any): Promise<IndividualResponseDto> => {
    const person = await api.getIndividualFromPerson(id, { id });
    return person;
}

export const createIndividualFromPerson = (payload: PersonUpdateDto): AppThunkPromise<IndividualResponseDto> => async (dispatch: any): Promise<IndividualResponseDto> => {
    const individual = await api.createIndividualFromPerson(payload);
    dispatch(actions.setIndividuals([individual]));

    return individual;
}

export const selectIndividualPerson = (individualId: string) => (state: RootState) => state.individual.individualPerson[individualId];

export const selectPersonIndividualId = (personId: string) => (state: RootState) => {
    let individualId = null;
    Object.keys(state.individual.individualPerson).forEach((key) => {
        if (state.individual.individualPerson[key]?.id === personId) {
            individualId = key;
        }
    });

    return individualId;
}

export const selectIndividuals = (state: RootState) => state.individual.individuals;

export const { reducer, actions } = individualSlice;


