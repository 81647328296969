import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { Role, ShoeboxItemResponseDto } from 'src/backend';
import { AppThunkPromise, RootState } from 'src/store';
import { processFileForUpload } from 'src/utils/process-file-for-upload';

import { licenseKeysSlice } from './license-keys';
import { loansManagerSlice } from './loan-manager';
import viewSlice from './view';


interface ShoeBoxState {
    // key is the item id
    items: Record<string, ShoeboxItemResponseDto>;
    // key is the user id
    uploading: Record<string, boolean>;
    // key is user id
    userUploadProgress: Record<string, number>;
}

const initialState: ShoeBoxState = {
    items: {},
    uploading: {},
    userUploadProgress: {}
};

export const shoeBoxSlice = createSlice({
    name: 'shoebox',
    initialState,
    reducers: {
        setItems(state: ShoeBoxState, action: { payload: { items: ShoeboxItemResponseDto[] } }): void {
            action.payload.items.forEach(item => {
                if (!isEqual(state.items[item.id], item)) {
                    state.items[item.id] = item;
                }
            });
            // if the item is not in the new list, remove it
            Object.keys(state.items).forEach(key => {
                if (!action.payload.items.find(item => item.id === key)) {
                    delete state.items[key];
                }
            });
        },
        removeItem: (state: ShoeBoxState, action: { payload: { itemId: string } }): void => {
            delete state.items[action.payload.itemId];
        },
        setUploading(state: ShoeBoxState, action: { payload: { userId: string, uploading: boolean } }): void {
            state.uploading[action.payload.userId] = action.payload.uploading;
        },
        setUserUploadProgress(state: ShoeBoxState, action: { payload: { userId: string, progress: number } }): void {
            state.userUploadProgress[action.payload.userId] = action.payload.progress;
        }
    }
});

export const { reducer, actions } = shoeBoxSlice;


export const createLoanShoeBoxItem = ({ loanId, file, userId }: { loanId: string, file: File, userId: string }): AppThunkPromise<ShoeboxItemResponseDto> => async (dispatch: any, getState): Promise<ShoeboxItemResponseDto> => {
    const { [licenseKeysSlice.name]: { pdftronKey } } = getState();
    dispatch(actions.setUploading({ userId, uploading: true }));
    const finalFile = await processFileForUpload(file, pdftronKey);
    const document = await api.uploadDocument({
        file: finalFile,
        name: finalFile.name,
        onUploadProgress: (progress: number) => {
            dispatch(actions.setUserUploadProgress({ userId, progress }));
        }
    });
    const item = await api.createShoeBoxItem({
        id: null,
        loanId,
        documentId: document.id,
        title: finalFile.name,
        userId
    });
    dispatch(actions.setUploading({ userId, uploading: false }));
    dispatch(getLoanShoeBoxItems(loanId));
    return item;
};

export const getLoanShoeBoxItems = (loanId: string): AppThunkPromise<ShoeboxItemResponseDto[]> => async (dispatch: any): Promise<ShoeboxItemResponseDto[]> => {
    const items = await api.getShoeBoxItemsByLoan({
        loan: loanId
    });
    if (Array.isArray(items)) {
        dispatch(actions.setItems({ items }));
        return items;
    } else {
        return []
    }
}

export const deleteShoeBoxItem = (id: string): AppThunkPromise<void> => async (dispatch: any): Promise<void> => {
    await api.deleteShoeBoxItem({
        id,
        documentId: null,
        loanId: null,
        title: null,
        userId: null
    });
    // remove document from state
    dispatch(actions.removeItem({ itemId: id }));
}

export const loanShoeBoxSelector = (loanId: string) => (state: RootState) => {
    return state.shoebox.items[loanId] || [];
}

export const isShoeboxUploadingSelector = (userId: string) => (state: RootState): boolean => {
    return state.shoebox.uploading[userId] || false;
}

export const selectUserShoeBoxById = ({ userId, loanId }: { userId: string, loanId: string }) => (state: { shoebox: ShoeBoxState }): ShoeboxItemResponseDto[] => {
    const list = Object.values(state.shoebox.items).flat();
    return list.filter(item => item.loanRole.user.id === userId && item.loanRole.loan === loanId)
        .sort((a, b) => {
            // sort alphabetically
            return a.title.localeCompare(b.title, 'en', { numeric: true });
        });
}

export const selectLoanTotalShoeBoxItems = (loanId: string) => createSelector([
    (state: RootState) => state[shoeBoxSlice.name].items,
    (state: RootState) => state[loansManagerSlice.name].loans[loanId],
    (state: RootState) => state[viewSlice.name].user
], (items, loan, user) => {
    const list = Object.values(items).flat();
    const onlyShowUserShoebox = loan.loanRoles.some(role => role.user.id === user.id && [Role.UNDERWRITER_LENDER, Role.BORROWER, Role.LEAD_BORROWER].includes(role.role))
    if (onlyShowUserShoebox) {
        return list.filter(item => item.loanRole.loan === loanId && item.loanRole.user.id === user.id).length;
    }
    return list.filter(item => item.loanRole.loan === loanId).length;
})

export const selectUserShoeBoxUploadProgress = (userId: string) => (state: { shoebox: ShoeBoxState }): number => {
    return state.shoebox.userUploadProgress[userId] || 0;
}