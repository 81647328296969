import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { api } from 'src/api'
import { EntityType } from 'src/constants/loan';
import type { AppThunk, RootState } from 'src/store';
import { API_STATUS } from 'src/types/view';

import { LoanPhaseCategoryType } from "../backend";
import { showSnackbar } from './snackbar';

export type ListItem = {
    label: string;
    value: string;
}

export type LoanPhaseListItem = {
    label: string;
    value: LoanPhaseCategoryType;
}

interface ListsState {
    states: ListItem[];
    loanStatuses: ListItem[];
    loanTypes: ListItem[];
    entityTypes: ListItem[];
    statesState: API_STATUS;
    knowledgeBases: ListItem[];
    systemUsersIds: string[];
    priorities: ListItem[];
    phaseCategories: LoanPhaseListItem[];
}

const initialState: ListsState = {
    states: [],
    loanStatuses: [],
    loanTypes: [],
    entityTypes: [],
    knowledgeBases: [],
    statesState: 'idle',
    systemUsersIds: [],
    priorities: [],
    phaseCategories: [],
};

export const listsSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {
        getUSStatesStateChange(state: ListsState, action: PayloadAction<API_STATUS>): void {
            state.statesState = action.payload
        },
        getJavascriptConstantsStateChange(state: ListsState, action: PayloadAction<API_STATUS>): void {
            state.statesState = action.payload
        },
        getStatesSuccess(state: ListsState, action: PayloadAction<ListItem[]>): void {
            state.states = action.payload
        },
        getJavascriptConstantsSuccess(state: ListsState, action: PayloadAction<{
            states: ListItem[],
            loanStatuses: ListItem[],
            loanTypes: ListItem[],
            entityTypes: ListItem[],
            knowledgeBases: ListItem[],
            systemUsersIds: string[],
            priorities: ListItem[],
            phaseCategories: LoanPhaseListItem[],
        }>): void {
            if (action.payload.states.length > 0) {
                state.states = action.payload.states;
            }
            if (action.payload.loanStatuses.length > 0) {
                state.loanStatuses = action.payload.loanStatuses;
            }
            if (action.payload.loanTypes.length > 0) {
                state.loanTypes = action.payload.loanTypes;
            }
            if (action.payload.entityTypes.length > 0) {
                state.entityTypes = action.payload.entityTypes;
            }

            if (action.payload.priorities.length > 0) {
                state.priorities = action.payload.priorities;
            }

            if (action.payload.phaseCategories.length > 0) {
                state.phaseCategories = action.payload.phaseCategories;
            }
            // sort knowledge bases alphabetically
            state.knowledgeBases = action.payload.knowledgeBases
                .sort((a, b) => a.value.localeCompare(b.value));

            state.systemUsersIds = action.payload.systemUsersIds;
        }
    }
});

export const { reducer } = listsSlice;

export const getUSStates = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(listsSlice.actions.getUSStatesStateChange('loading'));
    try {
        const states = await api.getUSStates()
        dispatch(listsSlice.actions.getStatesSuccess(states))
    } catch (err) {
        dispatch(showSnackbar({ severity: 'error', text: 'Error loading states' }))
    }
    dispatch(listsSlice.actions.getUSStatesStateChange('idle'));
};

export const getJavascriptConstants = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(listsSlice.actions.getJavascriptConstantsStateChange('loading'));
    try {
        const {
            states,
            loanStatuses,
            loanTypes,
            entityTypes,
            knowledgeBases,
            systemUsersIds,
            priorities,
            phaseCategories
        } = await api.getJavascriptConstants();
        dispatch(listsSlice.actions.getJavascriptConstantsSuccess({
            states,
            loanStatuses,
            loanTypes,
            entityTypes,
            knowledgeBases,
            systemUsersIds,
            priorities,
            phaseCategories
        }))
    } catch (err) {
        dispatch(showSnackbar({ severity: 'error', text: 'Error loading states' }))
    }
    dispatch(listsSlice.actions.getJavascriptConstantsStateChange('idle'));
};


export const listsStatesSelector = (state: RootState) => state.lists.states;

export const listsLoanStatusesSelector = createSelector([
    (state: RootState) => state.lists.loanStatuses,
    (state: RootState) => state.ui.hiddenPhases], (statuses, hiddenPhases) => {
        return statuses.filter((status) => !hiddenPhases?.includes(status.value));
    });

export const listsStatesStateSelector = createSelector((state: RootState) => state.lists.statesState, (statesState) => statesState);
export const listsLoanTypesSelector = (state: RootState) => state.lists.loanTypes;
export const listsEntityTypesSelector = (state: RootState) => state.lists.entityTypes;
export const listsKnowledgeBasesSelector = (state: RootState) => state.lists.knowledgeBases;
export const listsSystemUsersIdsSelector = (state: RootState) => state.lists.systemUsersIds;
export const phaseCategoriesList = createSelector((state: RootState) => state.lists.phaseCategories, (phaseCategories) => phaseCategories);
