import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router'
import { createSelector } from 'reselect';
import { api } from 'src/api';
import { IUser, LeadUserSetDto, LoanDto, LoanPhaseCategoryType, LoanReviewStatus, LoanViewType, Role } from 'src/backend';
import { OptionUser } from 'src/components/user/user-autocomplete';
import { RoleType } from 'src/constants/loan';
import { Route } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { Loan } from 'src/types/loan';
import { API_STATUS } from 'src/types/view'
import { filterLoanEntitiesIndividuals } from 'src/utils/loan/filter-loan-entities-individuals';
import { isIdABorrowerOnLoanRole } from 'src/utils/loan/is-id-borrower-on-loan-role';
import { isIdALenderOnLoanRole } from 'src/utils/loan/is-id-lender-on-loan-role';
import { transformLoan } from 'src/utils/loan/transform-loan';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { roleCan } from 'src/utils/user/role-can';

import { entitySlice } from './entity';
import { getPersonFromIndividual } from './individual';
import { loansManagerSlice } from './loan-manager';
import { handleRequestError } from './ui';

const DEFAULT_LOGO_KEY = 'default-logo';

interface LoanState {
    pendingGetLoan: Record<string, string>,
    primaryLenderName: string,
    loans: Record<string, Loan>;
    logos: Record<string, string>;
    createLoanState: API_STATUS,
    createLoanErrors: Record<string, string>
    createdLoan?: Loan | null,
    // when entityType is Person or DBA, we save the loan values in this object, until a person is created.
    // then we save both the loan and the person and invite the created person to the loan.
    unsavedFormLoan: object,
    loansCountByPhaseCategory: Record<LoanPhaseCategoryType, number>,
    // a map of loanId to the loan view type
    viewType: Record<string, LoanViewType | null>,
}

const initialState: LoanState = {
    logos: {},
    primaryLenderName: '',
    pendingGetLoan: {},
    loans: {},
    loansCountByPhaseCategory: {
        'ARCHIVE': 0,
        'ORIGINATION': 0,
        'LEAD': 0,
        'PORTFOLIO': 0,
    },
    createLoanState: 'idle',
    createLoanErrors: {},
    createdLoan: null,
    unsavedFormLoan: null,
    viewType: {},
};



export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        createLoanStateChange(state: LoanState, action: PayloadAction<API_STATUS>): void {
            state.createLoanState = action.payload
        },
        createdLoan(state: LoanState, action: PayloadAction<Loan>): void {
            state.createdLoan = action.payload
        },
        createLoanErrors(state: LoanState, action: PayloadAction<{ errors: { [x: string]: string } }>): void {
            state.createLoanErrors = action.payload.errors
        },
        togglePendingGetLoan(state: LoanState, action: PayloadAction<string>): void {
            if (typeof state.pendingGetLoan === 'undefined') {
                state.pendingGetLoan = {};
            }
            // add or remove pending get loan
            if (state.pendingGetLoan?.[action.payload]) {
                delete state.pendingGetLoan[action.payload]
            } else {
                state.pendingGetLoan[action.payload] = action.payload
            }
        },
        setLogo(state: LoanState, action: PayloadAction<{ id: string, url: string }>): void {
            state.logos[action.payload.id] = action.payload.url
        },
        setPrimaryLenderName(state: LoanState, action: PayloadAction<string>): void {
            state.primaryLenderName = action.payload;
        },
        unsavedFormLoan(state: LoanState, action: PayloadAction<object>): void {
            state.unsavedFormLoan = action.payload
        },
        setLoanViewType(state: LoanState, action: PayloadAction<{ loanId: string, viewType: LoanViewType | null }>): void {
            state.viewType[action.payload.loanId] = action.payload.viewType
        }
    }
});

export const { reducer } = loanSlice;

export const setLoanViewType = (loanId: string, viewType: LoanViewType | null): AppThunk => (dispatch): void => {
    dispatch(loanSlice.actions.setLoanViewType({ loanId, viewType }))
}

export const updateLoanPhase = ({ loanId, phaseId }: { loanId: string, phaseId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    try {
        await api.updateLoanPhase({ loanId, phaseId });
    } catch (e) { }
}

export const createdLoan = (loan: Loan | null): AppThunk => async (dispatch): Promise<void> => {
    dispatch(loanSlice.actions.createdLoan(loan))
    dispatch(createLoanStateChange('idle'))
}

export const createLoanStateChange = (state: API_STATUS): AppThunk => async (dispatch): Promise<void> => {
    dispatch(loanSlice.actions.createLoanStateChange(state))
}

export const setLoanReviewStatus = (loanId: string, status: LoanReviewStatus): AppThunkPromise<LoanDto> => async (dispatch): Promise<LoanDto> => {
    const loan = await api.setLoanReadyToReviewStatus(loanId, status);
    dispatch(setLoan(loan));
    return loan
}


export const setLoan = (loan: Loan): AppThunk => async (dispatch): Promise<void> => {
    const sanitizeHtml = (await import('sanitize-html')).default;
    const sanitizedLoan = {
        ...loan,
        loanPurpose: sanitizeHtml(loan.loanPurpose)
    }
    dispatch(loansManagerSlice.actions.appendLoans({
        loans: [sanitizedLoan],
        page: 1,
        phaseId: sanitizedLoan.loanPhase?.id
    }));

    const entities = {
        entities: loan?.loanEntities?.map(loanEntity => ({
            ...loanEntity,
            ...loanEntity.mostRecentUserInformation,
            ...loanEntity.sherpaEntity,
            ...loanEntity.assetEntityCustomizations,
            label: loanEntity.label,
            customizations: loanEntity.assetEntityCustomizations,
            templateId: loanEntity.templateId,
            templateName: loanEntity.templateName,
        })),
        loanId: loan.id
    }

    dispatch(entitySlice.actions.setLoanEntities(entities));
}

export const getLoan = (id: string): AppThunkPromise<Loan> => async (dispatch, getState): Promise<Loan> => {
    const { loan: { pendingGetLoan } } = getState();
    if (pendingGetLoan?.[id]) return;
    dispatch(loanSlice.actions.togglePendingGetLoan(id))
    try {
        const result = await api.getLoan(id);
        if (typeof result?.id !== 'undefined') {
            dispatch(setLoan(result));
            dispatch(loanSlice.actions.togglePendingGetLoan(id));
            result.loanEntities.filter(filterLoanEntitiesIndividuals).forEach(loanEntity => {
                dispatch(getPersonFromIndividual(loanEntity.sherpaEntity.id));
            });
        }
        return result;
    } catch (error) {
        toast({
            type: 'error',
            content: `Loan either does not exist or you are not authorized to access, please contact your mysherpas admin if this continues`
        })
        // if loan is not found or if error happened redirect to homepage
        if (error.status === 404 || error.status === 400 || error.status === 403) {
            Router.replace({
                pathname: Route.HOME,
            });
        }
    }
}

export const getEntityLogo = (id: string): AppThunk => async (dispatch, getState): Promise<void> => {
    const { loan: { logos } } = getState();
    if (typeof logos[DEFAULT_LOGO_KEY] !== 'undefined') return;
    try {
        const logoUrl = await api.getEntityLogo(id);
        dispatch(loanSlice.actions.setLogo({ id: DEFAULT_LOGO_KEY, url: logoUrl }));
    } catch (error) {
        console.error('Error requesting /lenders/logo endpoint', error);
    }
}

export const reGenerateCreditReport = ({ loanId, personId }: {
    loanId: string,
    personId: string
}) => async (): Promise<void> => {
    await api.reGenerateCreditReport({
        loanId,
        personId,
        forceSchedule: true
    })
}

export const setUnsavedFormLoan = (loan: object): AppThunk => async (dispatch): Promise<void> => {
    dispatch(loanSlice.actions.unsavedFormLoan(loan));
}

export const addLenderToLoan = ({ loanId, payload }: {
    loanId: string,
    payload: LeadUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    const loan = await api.addLender(loanId, {
        appUser: payload.appUser,
        newToLoan: payload.newToLoan,
        leadLender: payload.leadLender,
        leadBorrower: payload.leadBorrower,
        role: payload.role,
        canAcceptFiles: payload.canAcceptFiles,
        visibleToBorrower: payload.visibleToBorrower
    });
    dispatch(setLoan(loan));
    return loan;
}

export const editLenderOnLoan = ({ loanId, payload }: {
    loanId: string,
    payload: LeadUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    const loan = await api.editLender(loanId, {
        appUser: payload.appUser,
        newToLoan: payload.newToLoan,
        leadLender: payload.leadLender,
        leadBorrower: payload.leadBorrower,
        role: payload.role,
        canAcceptFiles: payload.canAcceptFiles,
        visibleToBorrower: payload.visibleToBorrower
    });
    dispatch(setLoan(loan));
    return loan;
}

export const removeRoleFromLoan = (loanId: string, roleId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const loan = await api.removeLender(loanId, roleId);
        dispatch(setLoan(loan));
    } catch (error) {
        notifyBugTracker(error);
        dispatch(handleRequestError(error));
    }
}

export const addBorrowerUser = ({ loanId, userId, role, doesNeedOnboarding }: {
    loanId: string,
    userId: string,
    doesNeedOnboarding: boolean,
    role: Role
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    try {
        const loan = await api.addBorrowerUser(loanId, {
            appUser: userId,
            role,
            doesNeedOnboarding
        });
        dispatch(setLoan(loan));

        return loan;

    } catch (error) {
        dispatch(handleRequestError(error));
    }
}

export const setUserRoleOnLoan = ({ loanId, userId, role }: {
    loanId: string,
    userId: string,
    role: Role
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    try {
        const loan = await api.setUserRole(loanId, {
            appUser: userId,
            leadBorrower: role === RoleType.LeadBorrower,
            leadLender: role === RoleType.LeadLender,
            role,
            newToLoan: false,
            visibleToBorrower: role !== RoleType.Borrower,
            canAcceptFiles: role !== RoleType.Borrower
        });
        dispatch(setLoan(loan));
        return loan;
    } catch (error) {
        dispatch(handleRequestError(error));
    }
}

export const getLoanLogo = (loanId: string): AppThunk => async (dispatch, getState): Promise<void> => {
    const { loan: { logos } } = getState();
    if (typeof logos?.[loanId] !== 'undefined') return;
    try {
        const logoUrl = await api.getLoanLogo(loanId);
        dispatch(loanSlice.actions.setLogo({ id: loanId, url: logoUrl }));
    } catch (error) {
        console.error('Error requesting /loans/logo endpoint', error);
    }
}

export const toggleLoanPinning = (loanId: string, pinned: boolean): AppThunk => async (dispatch): Promise<void> => {
    if (pinned) {
        await api.unpinLoan(loanId);
    } else {
        await api.pinLoan(loanId);
    }
    // update loan pinning
    dispatch(loansManagerSlice.actions.toggleLoanPin({ loanId, pinned: !pinned }));
}

// selectors
export const createLoanErrorsSelector = (state: RootState) => state.loan.createLoanErrors;
export const createLoanStateSelector = (state: RootState) => state.loan.createLoanState;
export const createdLoanSelector = (state: RootState) => state.loan.createdLoan;

export const loanViewTypeSelector = (loanId: string) => createSelector([
    (state: RootState) => state.loan.viewType[loanId]
], (viewType) => {
    return viewType;
});


export const contextLoanLogoSelector = createSelector([(state: RootState) => state.loan.logos, (state: RootState) => state.view.currentCard], (logos, currentCard) => {
    if (currentCard) {
        return logos[currentCard?.body?.lender?.id];
    }

    return logos[DEFAULT_LOGO_KEY];
});

export const contextLenderNameSelector = createSelector([
    (state: RootState) => state.view.currentCard,
    (state: RootState) => state.loan.primaryLenderName,
    (state: RootState) => state.view.employer,
], (currentCard, primaryLenderName, employer) => {
    let lenderName = primaryLenderName;
    if (!!currentCard?.body?.lender?.name) {
        lenderName = currentCard?.body?.lender?.name;
    } else if (!!employer) {
        lenderName = employer.name;
    }

    return lenderName;
});

export const contextLoanSelector = createSelector([
    (state: RootState) => state.view.currentLoanId,
    (state: RootState) => state[loansManagerSlice.name].loans],
    (currentLoanId, loans) => {
        return transformLoan(loans[currentLoanId]);
    });

export const contextLoanBorrowersIdsSelector = createSelector([
    (state: RootState) => state.view.currentLoanId,
    (state: RootState) => state[loansManagerSlice.name].loans],
    (currentLoanId, loans) => {
        return [...(loans[currentLoanId]?.borrowers ?? []), ...(loans[currentLoanId]?.pendingInviteUsers ?? [])].map(b => b.id);
    });

export const loanSelector = (id: string) => createSelector(
    [
        (state: RootState) => state[loansManagerSlice.name].loans[id],
    ],
    (loan) => {
        return transformLoan(loan);
    }
);

export const selectIsLoggedUserLeadLenderOnLoan = (loanId: string) => createSelector(
    [(state: RootState) => state[loansManagerSlice.name].loans, (state: RootState) => state.view.user],
    (loans, loggedInUser) => {
        const loan = loans[loanId];
        if (!loan) return false;
        return loan.loanRoles.some(role => role.user.id === loggedInUser.id && role.role === RoleType.LeadLender);
    }
);

export const selectCanLoggedInUserSendMessagesToBorrowers = (loanId: string) => createSelector(
    [(state: RootState) => state[loansManagerSlice.name].loans, (state: RootState) => state.view.user],
    (loans, loggedInUser) => {
        const loan = loans[loanId];
        if (!loan) return false;
        const userRole = loan.loanRoles.find(role => role.user.id === loggedInUser.id);

        if (!userRole) return false;

        if (userRole.role === RoleType.Lender && !userRole.visibleToBorrower) return false;

        return true;
    }
);

export const selectLoanChatRecipients = (loanId: string) => createSelector<[(state: RootState) => Record<string, Loan>, (state: RootState) => IUser], OptionUser[]>(
    [(state: RootState) => state[loansManagerSlice.name].loans, (state: RootState) => state.view.user],
    (loans, loggedInUser) => {
        if (!loanId) {
            return [];
        }

        const loan = loans[loanId];

        if (!loan?.id) {
            return [];
        }

        const borrowers = [];
        const lenders = [];
        loan?.loanRoles.filter((role) => isIdALenderOnLoanRole(role.user.id, role))
            .forEach(({ user }) => {
                lenders.push({
                    id: user.id,
                    fullName: getUserDisplayName(user),
                    group: loan.lender?.name,
                });
            });
        const loanRole = loan.loanRoles.find(loanRole => loanRole.user.id === loggedInUser.id);
        const role = loanRole?.role ?? loggedInUser.roleDefault;

        const canMessageBorrowers = (!!loanRole &&
            loanRole.visibleToBorrower &&
            roleCan(loanRole.role, Permission.BorrowerTeamCommunication)) ||
            (loanRole && role === Role.UNDERWRITER_LENDER && loanRole.visibleToBorrower) ||
            role === RoleType.Manager ||
            loanRole?.role === RoleType.LeadLender;

        loan?.loanRoles.filter((role) => isIdABorrowerOnLoanRole(role.user.id, role) &&
            canMessageBorrowers).forEach(({ user }) => {
                borrowers.push({
                    id: user.id,
                    fullName: getUserDisplayName(user),
                    group: 'Applicant Team',
                });
            });
        return [
            ...borrowers.sort((a, b) => a.fullName.localeCompare(b.fullName)),
            ...lenders.sort((a, b) => a.fullName.localeCompare(b.fullName))
        ]
    }
);

export default loanSlice;