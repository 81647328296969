import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { ElementModifierType, StorageType, TemplateElementRequestDto, TemplateElementResponseDto } from 'src/backend';
import { RootState } from 'src/store';
import { TemplateElementTree } from 'src/types/formelement';
import { sortFromElements } from 'src/utils/form-element-transformer';
import { toast } from 'src/utils/toast';


interface TemplateElementV2State {
    elements: Record<string, Record<string, TemplateElementResponseDto>>;
}

const initialState: TemplateElementV2State = {
    elements: {}
};

export const elementV2Slice = createSlice({
    name: 'elementv2',
    initialState,
    reducers: {
        setElements(
            state: TemplateElementV2State,
            action: PayloadAction<{ elements: Record<string, TemplateElementResponseDto>, templateId: string }>
        ): void {
            Object.keys(action.payload.elements).forEach((key) => {
                if (!state.elements[action.payload.templateId]) {
                    state.elements[action.payload.templateId] = {};
                }
                state.elements[action.payload.templateId][key] = action.payload.elements[key];
            });
        },
        deleteElement(
            state: TemplateElementV2State,
            action: PayloadAction<{ element: TemplateElementRequestDto, templateId: string }>
        ): void {
            if (state.elements[action.payload.templateId]) {
                delete state.elements[action.payload.templateId][action.payload.element.templateElementId];
            }
        }
    }
});

export const createV2TemplateElement = (element: TemplateElementRequestDto, templateId: string) => async (dispatch: any) => {
    try {
        const elements = await api.createV2TemplateElements({ elements: [element] });
        dispatch(actions.setElements({
            elements,
            templateId
        }));
    } catch (e) {
        toast({
            content: 'Error while creating template element',
            type: 'error'
        })
    }
}

export const updateV2TemplateElement = (element: Partial<TemplateElementRequestDto>, templateId: string) => async (dispatch: any) => {
    try {
        const elements = await api.updateV2TemplateElements({ elements: [element] });
        dispatch(actions.setElements({
            elements,
            templateId
        }));
    } catch (e) {
        toast({
            content: 'Error while updating template element',
            type: 'error'
        })
    }
}

export const answerV2TemplateElement = ({ element, file }: { element: TemplateElementResponseDto, file: File }) => async (dispatch: any) => {
    try {
        const document = await api.uploadDocument({
            file,
            name: element.title,
        });
        const answer = await api.answerV2TemplateElement({
            answerId: null,
            elementId: element.templateElementId,
            documentId: document.id
        })
        dispatch(actions.setElements({
            elements: {
                [answer.templateElementId]: answer
            },
            templateId: element.templateId
        }));
    } catch (error) {
        toast({
            content: 'Error while uploading document',
            type: 'error'
        })
    }
}

export const getV2TemplateElements = (templateId: string) => async (dispatch: any) => {
    try {
        const elements = await api.getV2TemplateElements({ templateId });
        dispatch(actions.setElements({
            elements,
            templateId
        }));
    } catch (e) {
        toast({
            content: 'Error while fetching template elements',
            type: 'error'
        })
    }
}

export const deleteV2TemplateElement = (element: TemplateElementRequestDto, templateId: string) => async (dispatch: any) => {
    await api.deleteV2TemplateElements({ elements: [element] });
    dispatch(actions.deleteElement({ element, templateId }));
}

export const { reducer, actions } = elementV2Slice;

export const v2TemplateElementsSelector = (id: string) => createSelector((state: RootState) => state.elementv2.elements, elements => {
    return elements[id] ? Object.values(elements[id]).flat() : [];
})


// function that recursively creates a tree of elements using parentId
export const elementsTree = <T extends { modifiers: ElementModifierType[], storageType: StorageType, parentId: string, templateElementId: string, title: string, evaluatedTitle?: string }>(elements: T[], idKey: string, parentIds = []): TemplateElementTree<T>[] => {
    const getChildren = (parentId: string) => {
        return elements.filter((element) => element.parentId === parentId);
    }

    const createTree = (parentId: string) => {
        const children = getChildren(parentId);
        const sortedChildren = children.map((child) => {
            return {
                id: child[idKey],
                name: child.evaluatedTitle ?? child.title,
                title: child.evaluatedTitle ?? child.title,
                element: child,
                storageType: child.storageType,
                modifiers: child.modifiers,
                children: createTree(child[idKey])
            }
        }).sort(sortFromElements);
        return sortedChildren;
    }

    return parentIds.map(parentId => {
        const parent = elements.find((element) => element[idKey] === parentId);
        return ({
            displayOrder: 0,
            storageType: parent.storageType,
            id: parent[idKey],
            name: parent.title,
            title: parent.title,
            element: parent,
            modifiers: parent.modifiers,
            children: createTree(parentId)
        })
    }).sort(sortFromElements);
}

// selector that returns a tree of elements
export const v2TemplateElementsTreeSelector = (id: string) => createSelector((state: RootState) => state, state => {
    const elements = v2TemplateElementsSelector(id)(state);
    if (!elements.length) return [];
    const rootElement = elements.find((element) => element.parentId === null || !element.parentId);
    if (!rootElement) return [];
    return elementsTree(elements, 'templateElementId', [rootElement.templateElementId]);
})




