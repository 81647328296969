import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { FormElement } from 'src/types/view';
import { flattenSections, mapFormElementToCalendarEvent } from 'src/utils';

import type { AppThunk } from '../store';
import type { MysherpasCalendarEvent } from '../types/calendar';

interface CalendarState {
  events: MysherpasCalendarEvent[];
}

const initialState: CalendarState = {
  events: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(
      state: CalendarState,
      action: PayloadAction<MysherpasCalendarEvent[]>
    ): void {
      state.events = action.payload;
    }
  }
});

export const { reducer, actions } = slice;

export const getEvents = (): AppThunk => async (dispatch, getState): Promise<void> => {
  return;
  // const { currentLoanId } = getState().view;
  // const { formElements } = getState().formElement;

  // let formElementsEvents: FormElement[] = []

  // formElementsEvents = Object.keys(formElements)
  //   .filter(key => (!currentLoanId || currentLoanId === key))
  //   .reduce((acc: FormElement[], curr: string) => {
  //     return [...acc, { ...formElements[curr], loanId: curr }]
  //   }, [])

  // const flattenedFormElements = flattenSections(formElementsEvents)

  // dispatch(slice.actions.getEvents(flattenedFormElements.map(mapFormElementToCalendarEvent)));
};

export const calendarEventsSelector = (state: any) => state.calendar.events;