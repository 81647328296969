import { DocusignViewDTO, PaymentFormDTO, WorkflowEntity, WorkflowPhaseAgentEntity, WorkflowPhaseEntity, WorkflowPhaseStatus, WorkflowPhaseType } from 'src/backend';

import { baseApi } from './baseApi';

const sortPhases = (phases: WorkflowPhaseEntity[]) => {
    return phases.sort((a, b) => a.orderAction - b.orderAction);
}

export const workFlowApi = baseApi.enhanceEndpoints({ addTagTypes: ['WorkflowEntity'] }).injectEndpoints({
    endpoints: (build) => ({
        getWorkFlowAssignments: build.query<WorkflowPhaseAgentEntity[], { packageInfoId: string }>({
            query: (body) => ({
                url: `/v2/workflow/assignments/${body.packageInfoId}`,
                method: 'GET',
            }),
            transformResponse: (response: WorkflowPhaseAgentEntity[]) => {
                return response;
            },
        }),
        getMyWorkFlows: build.query<WorkflowEntity[], void>({
            providesTags: ['WorkflowEntity'],
            query: () => ({
                url: `/v2/workflow/foruser`,
                method: 'GET',
            }),
            transformResponse: (response: WorkflowEntity[], meta) => {
                return response.map((wf) => ({
                    ...wf,
                    phases: sortPhases(wf.phases),
                }));
            }
        }),
        saveWorkFlowEntityName: build.mutation<WorkflowEntity, { packageInfoId: string, form: PaymentFormDTO, workFlowId: string, phaseId: string }>({
            query: (body) => ({
                url: `/v2/workflow/form/payment/${body.packageInfoId}`,
                method: 'POST',
                data: body.form,
            }),
            transformResponse: (response: WorkflowEntity) => {
                return response;
            },
        }),
        setPhaseStatus: build.mutation<WorkflowEntity, { packageInfoId: string, workFlowId: string, phaseId: string, phaseType: WorkflowPhaseType, phaseStatus: string }>({
            query: (body) => ({
                url: `/v2/workflow/phase/${body.phaseType}/${body.packageInfoId}/${body.phaseStatus}`,
                method: 'PUT',
            }),
            transformResponse: (response: WorkflowEntity) => {
                return response;
            },
            async onQueryStarted({ workFlowId, phaseId }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    workFlowApi.util.updateQueryData('getMyWorkFlows', undefined, (workflows) => {
                        // optimistic update
                        // set the workflow phase status to DONE in the cache
                        return workflows.map((wf) => {
                            if (wf.id === workFlowId) {
                                return {
                                    ...wf,
                                    phases: wf.phases.map((phase) => {
                                        if (phase.id === phaseId) {
                                            return {
                                                ...phase,
                                                status: WorkflowPhaseStatus.DONE,
                                            }
                                        }
                                        return phase;
                                    }),
                                }
                            }
                            return wf;
                        }
                        );
                    }))
                try {
                    await queryFulfilled
                } catch (error) {
                    patchResult.undo()
                }
            },
        }),
        getSelfSignUrl: build.query<DocusignViewDTO, { packageInfoId: string }>({
            query: (body) => ({
                url: `/v2/workflow/createSelfSign/${body.packageInfoId}`,
                method: 'GET',
            }),
            transformResponse: (response: DocusignViewDTO) => {
                return response;
            },
        }),
        checkEnvelopeStatus: build.mutation<DocusignViewDTO, { packageInfoId: string, envelopeId: string }>({
            query: (body) => ({
                url: `/v2/workflow/docusign/${body.packageInfoId}/${body.envelopeId}`,
                method: 'PUT',
            }),
            transformResponse: (response: DocusignViewDTO) => {
                return response;
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetWorkFlowAssignmentsQuery,
    useCheckEnvelopeStatusMutation,
    useGetSelfSignUrlQuery,
    useSaveWorkFlowEntityNameMutation,
    useSetPhaseStatusMutation,
    useGetMyWorkFlowsQuery,
    reducerPath,
    middleware,
    reducer,
} = workFlowApi;



