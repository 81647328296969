import { type PayloadAction,createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api'
import type { AppThunk, RootState } from 'src/store';
import { SearchItem, SearchResult } from 'src/types/search';

type SearchDevice = 'mobile' | 'desktop';

const DESKTOP_MAX_RESULTS = 15;
const MOBILE_MAX_RESULTS = 5;

interface SearchState {
    page: number;
    perPage: number;
    totalRecords: number;
    totalPages: number;
    result: Record<string, SearchItem[]>;
    device: SearchDevice;
    loading?: boolean;
}

const initialState: SearchState = {
    page: 0,
    perPage: 15,
    totalRecords: 0,
    totalPages: 0,
    result: {},
    device: 'desktop',
    loading: false
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setDevice(state: SearchState, action: PayloadAction<SearchDevice>): void {
            state.device = action.payload
        },
        setResult(state: SearchState, action: PayloadAction<{ page: number; data: any }>): void {
            state.result = {
                ...state.result,
                [action.payload.page]: action.payload.data
            }
        },
        setLoading(state: SearchState, action: PayloadAction<boolean>): void {
            state.loading = action.payload
        },
        setPage(state: SearchState, action: PayloadAction<number>): void {
            state.page = action.payload
        },
        setTotal(state: SearchState, action: PayloadAction<number>): void {
            state.totalRecords = action.payload
        },
        setTotalPages(state: SearchState, action: PayloadAction<number>): void {
            state.totalPages = action.payload
        }
    }
});

export const setSearchDevice = (device: SearchDevice): AppThunk => async dispatch => {
    dispatch(searchSlice.actions.setDevice(device))
};

export const doSearch = (query: string, page: number = 1): AppThunk => async (dispatch, getState): Promise<void> => {
    const { search: { device }, view: { currentLoanId } } = getState();
    const perPage = device === 'mobile' ? MOBILE_MAX_RESULTS : DESKTOP_MAX_RESULTS;
    dispatch(searchSlice.actions.setLoading(true));
    dispatch(searchSlice.actions.setResult({ page, data: [] }));
    try {
        const response: SearchResult = await api.search({ query, page, device, contextLoanId: currentLoanId });
        dispatch(searchSlice.actions.setResult({ page, data: response.items }));
        dispatch(searchSlice.actions.setTotalPages(Math.ceil(response.total / perPage)));
        dispatch(searchSlice.actions.setTotal(response.total));
        dispatch(searchSlice.actions.setPage(page));
    } catch (error) {

    }

    dispatch(searchSlice.actions.setLoading(false));
}

export const resetSearch = (): AppThunk => async dispatch => {
    dispatch(searchSlice.actions.setResult({ page: 1, data: [] }));
    dispatch(searchSlice.actions.setTotalPages(0));
    dispatch(searchSlice.actions.setTotal(0));
    dispatch(searchSlice.actions.setPage(1));
    dispatch(searchSlice.actions.setLoading(false));
};

export const searchLoadingSelector = (state: RootState): boolean => state.search.loading;

export const searchResultSelector = (page: number) => createSelector<[(state: RootState) => any], SearchItem[]>(
    (state: RootState) => state.search.result,
    (result) => Object.values(result[page] || [])
);

export const moreResultsEnabledSelector = createSelector(
    (state: RootState) => state.search.device,
    (state: RootState) => state.search.totalRecords,
    (device, totalRecords) => totalRecords > DESKTOP_MAX_RESULTS && device === 'desktop' || totalRecords > MOBILE_MAX_RESULTS && device === 'mobile'
);

export const { reducer } = searchSlice;