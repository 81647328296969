import { createSlice } from "@reduxjs/toolkit";

import { get, put } from "../api";
import { AppThunk } from "../store";

interface RemoteSettingsState {
    sendSmsNotificationOnMessageReceived: boolean
}

interface AllSettings {
    userPrefs?: RemoteSettingsState
}


export const slice = createSlice({
    name: "settings",
    initialState: {},
    reducers: {
        setLoadingPreferences(state: AllSettings): void {
            state.userPrefs = undefined
        },

        setPreferences(state: AllSettings, action: { payload: RemoteSettingsState }): void {
            state.userPrefs = action.payload;
        },
    }
})

export const { reducer } = slice;

export function postRemotePreferences(state: RemoteSettingsState): AppThunk {
    return async (dispatch): Promise<void> => {
        await dispatch(slice.actions.setLoadingPreferences())
        await put("/auth/userSettings", state);
        await dispatch(fetchRemotePreferences());
    }
}

export function fetchRemotePreferences(): AppThunk {
    return async dispatch => {
        await dispatch(slice.actions.setLoadingPreferences())
        let serverSettings = await get<RemoteSettingsState>("/auth/userSettings")
        dispatch(slice.actions.setPreferences(serverSettings))
    }
}
