
import { FieldTask } from 'src/types/tasks';

import { fileToBlob } from './fileToBlob';
import { handleAnnotationImported } from './pdftron-viewer';
import { getAssetPath } from './url/get-asset-path';

export const getFileFieldTasks = async (file: File | Blob, id: string, pdftronKey): Promise<FieldTask[]> => {
    return new Promise(async (resolve, reject) => {
        const blob = file instanceof File ? await fileToBlob(file) : file;
        const viewerDiv = document.createElement('div');
        viewerDiv.style.display = 'none';
        viewerDiv.style.position = 'absolute';
        viewerDiv.style.visibility = 'hidden';
        document.body.appendChild(viewerDiv);
        try {
            const WebViewer = (await import('@pdftron/webviewer')).default;
            const instance = await WebViewer(
                {
                    licenseKey: pdftronKey,
                    isReadOnly: true,
                    fullAPI: true,
                    disableLogs: true,
                    path: getAssetPath('static/webviewer10'),
                    enableAnnotations: false,
                },
                viewerDiv,
            );

            const annotationsChangedHandler = () => {
                handleAnnotationImported({
                    doNotSetPdfTron: true,
                    canAssignSignature: false,
                    pdfTronInstance: instance,
                    loggedInUserId: id,
                    onRequiredFields: (requiredFields) => {
                        resolve(requiredFields);
                        document.body.removeChild(viewerDiv);
                        instance.Core.annotationManager.removeEventListener('annotationsLoaded', annotationsChangedHandler);
                    },
                });
            }
            instance.Core.documentViewer.addEventListener('annotationsLoaded', annotationsChangedHandler);
            instance.UI.loadDocument(blob, { filename: 'pdfFile' });
        } catch (error) {
            document.body.removeChild(viewerDiv);
            reject(error);
        }
    });
}
