const I18NStrings = {
    "en": {
        'max255': 'Must be at most 255 characters',
        "required": "Required",
        "fillAndSign": "Fill & Sign",
        "assignSignee": "Assign Signee",
        "shoeBox": "ShoeBox",
        "accept": "Accept",
        "upload": "Upload",
        "acceptThisDocument": "Accept this document",
        "acceptedDocuments": "Accepted Documents",
        "approvedBy": "Approved by",
        "acceptedBy": "Accepted by",
        "acceptSelected": 'Accept Selected',
        "documentsAccepted": "Documents Accepted",
        "documentExpired": "Document Expired",
        "loanProgress": "Loan Progress",
        "uploadedDocuments": "Uploaded Documents",
        "accepted": "Accepted",
        "approved": "Approved",
        "answered": "Answered",
    }
};

export const getString = (text: string): string => {
    return I18NStrings['en'][text];
}