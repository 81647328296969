import { endOfDay, format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { Loan } from "src/types/loan";

export const transformLoan = (loan: Loan): Loan => {
    if (!loan) {
        return null;
    }
    const hasClosedDate = !!loan.closeDate;
    const parsedCloseDate = hasClosedDate ? parseISO(loan.closeDate) : null;
    let closeDateDistance = '';
    try {
        closeDateDistance = hasClosedDate ? formatDistanceToNow(endOfDay(parsedCloseDate)) : '';
    } catch (e) {
    }

    return ({
        ...loan,
        closeDate: loan.closeDate,
        loanProgress: (loan.percentageApproved + loan.percentageAnswered) / 2,
        closeDateDistance,
        closeDateYearFormatted: hasClosedDate ? format(parsedCloseDate, "MMM d yyyy").toUpperCase() : '',
        createdDateYearFormatted: loan.createdDate ? format(parseISO(loan.createdDate), "MMM d yyyy").toUpperCase() : '',
        closeDateFormatted: hasClosedDate ? format(parsedCloseDate, "MMM d, yyyy") : '',
        createdDateFormatted: loan.createdDate ? format(parseISO(loan.createdDate), "MMM d, yyyy") : '',
        // date-fns is passed closeDate
        closeDateIsPassed: (hasClosedDate && !!parsedCloseDate) ? isPast(endOfDay(parsedCloseDate)) : false,
        borrowers: [...loan.borrowers].sort((a, b) => a.id > b.id ? 1 : -1),
        borrowerTeamUsers: [...loan.borrowerTeamUsers].sort((a, b) => a.id > b.id ? 1 : -1),
    })
}
