import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { api } from 'src/api';
import { LOAN_MESSAGES_PER_PAGE } from 'src/constants/chat';
import { LoanSidebar } from 'src/constants/ui';
import { fileFormElementReplies, noContextReplies, personReplies, sectionFormElementReplies } from 'src/mocks/chat';
import { getToastDuration } from 'src/utils';
import { generateUUID } from 'src/utils/generate-uuid';
import { toast } from 'src/utils/toast';

import type { AppThunk, RootState } from '../store';
import type { ChatMessageContext, Contact, Message, Participant, Thread } from '../types/chat';
import { setOpenLoanSidebar } from './ui';

export interface APILoanMessage {
  subject: string;
  body: string;
  toUserIds: string[];
  toUserNames?: string[];
  replyToId?: string;
  name?: string;
  username?: string;
  messageType?: 'DIGEST';
  receiverId: string;
  senderId: string;
  receiverName: string;
  contextId: string;
  readAt?: string | null;
}
interface ChatState {
  pendingFetchNewMessages: Record<string, string>;
  filters: {
    filterByUser: string
  },
  activeLoanId?: string;
  contacts: {
    byId: Record<string, Contact[]>;
    allIds: string[];
  };
  threads: {
    byId: Record<string, Thread>;
    allIds: string[];
  };
  participants: Participant[];
  recipients: any[];
  replyToMessage?: Message;
  draftMessage?: Message;
  quickReplies: string[];
  messagesHTML: {
    byId: Record<string, string>;
    allIds: string[];
  },
  showMoreStateIds: Record<string, boolean>;
}

const initialState: ChatState = {
  pendingFetchNewMessages: {},
  filters: {
    filterByUser: null
  },
  activeLoanId: null,
  contacts: {
    byId: {},
    allIds: []
  },
  threads: {
    byId: {},
    allIds: []
  },
  participants: [],
  recipients: [],
  replyToMessage: null,
  draftMessage: null,
  quickReplies: [],
  messagesHTML: {
    byId: {},
    allIds: []
  },
  showMoreStateIds: {}
};

const sanitizeMessages = async (messages: Message[]): Promise<Message[]> => {
  const sanitizeHtml = (await import('sanitize-html')).default;
  return messages.map(message => {
    message.body = sanitizeHtml(message.body, {
      allowedAttributes: {
        'a': ['href', 'name', 'target'],
        'ol': ['start']
      }
    })
    return message;
  });
}


const showNewMessagesToasts = async (messages: Message[], openChatSidebarCallback: () => void) => {
  const sanitizeHtml = (await import('sanitize-html')).default;

  messages.forEach(message => {
    // regex to remove all special characters like &amp;
    const cleanPreview = sanitizeHtml(message.preview, {
      allowTags: [],
      allowedAttributes: {}
    }).replace(/(<([^>]+)>)/ig, '').replace(/&[^;]+;/g, '');

    const toastMessage = `${message.subject} : ${cleanPreview}`
    const duration = getToastDuration(toastMessage);

    // warning whn creating a package
    const isPackageMessage = message.subject.includes('Loan package generation status');
    const isWarning = isPackageMessage && message.body.includes('generated partially');
    const isError = isPackageMessage && message.body.includes('failed')
    if (isError) {
      toast({
        type: 'error',
        content: toastMessage,
        duration
      })
    } else if (isWarning) {
      toast({
        type: 'error',
        content: toastMessage,
        duration
      });
    } else {
      toast({
        type: 'success',
        onClick: openChatSidebarCallback,
        content: toastMessage,
        duration
      })
    }

  });
}

const isUserTheReceiver = (message: Message, userId: string): boolean => {
  return message.receiverId === userId;
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getLoanTeamMembers(state: ChatState, action: PayloadAction<{ loanId: string, contacts: Contact[] }>): void {
      const { contacts, loanId } = action.payload;
      state.contacts.byId = {
        ...state.contacts.byId,
        [loanId]: contacts
      }
    },
    getLoanMessages(state: ChatState, action: PayloadAction<{ data: Message[], loanId: string, page: number }>): void {
      const { loanId, data, page } = action.payload

      const fetchedThreads = Object.keys(state.threads.byId)

      if (fetchedThreads.includes(action.payload.loanId)) {
        const currentThread = state.threads.byId[loanId];
        const diffMessages = data.filter(message => !currentThread.messages.some(currentMessage => currentMessage.id === message.id));
        const newThread = {
          ...currentThread,
          page,
          messages: [
            ...diffMessages,
            ...currentThread.messages,
          ],
          hasMoreMessages: data.length >= LOAN_MESSAGES_PER_PAGE,
          isLoading: false
        };
        if (!isEqual(state.threads.byId[loanId], newThread)) {
          state.threads.byId[loanId] = newThread;
        }
      } else {
        state.threads.byId = {
          ...state.threads.byId,
          [loanId]: {
            id: loanId,
            page,
            messages: data,
            participants: [],
            contacts: [],
            hasMoreMessages: data.length >= LOAN_MESSAGES_PER_PAGE,
            isLoading: false
          }
        };
        if (state.threads.allIds.indexOf(loanId) === -1) {
          state.threads.allIds = [
            ...fetchedThreads,
            loanId
          ];
        }
      }
    },
    fetchNewMessages(state: ChatState, action: PayloadAction<{ data: Message[], loanId: string }>): void {
      const { loanId, data } = action.payload
      const storedThreads = Object.keys(state.threads.byId)

      if (storedThreads.includes(action.payload.loanId)) {
        const storedThread = state.threads.byId[loanId]
        const storedMessages = storedThread.messages

        // Get the rest of the new messages not yet rendered in the chat sidebar
        const newMessages = data
          .filter(message => !storedMessages.some(currentMessage => currentMessage.id === message.id))

        state.threads.byId = {
          ...state.threads.byId,
          [loanId]: {
            ...storedThread,
            messages: [
              ...storedMessages,
              ...newMessages,
            ]
          }
        };

      }
    },
    loadingLoanMessages(state: ChatState, action: PayloadAction<{ loanId: string }>): void {
      const { loanId } = action.payload
      const fetchedThreads = Object.keys(state.threads.byId)
      if (fetchedThreads.includes(loanId)) {
        state.threads.byId = {
          ...state.threads.byId,
          [loanId]: {
            ...state.threads.byId[loanId],
            isLoading: true
          }
        };
      } else {
        state.threads.byId = {
          ...state.threads.byId,
          [loanId]: {
            id: loanId,
            page: 1,
            messages: [],
            participants: [],
            contacts: [],
            isLoading: true
          }
        };
        state.threads.allIds = [
          ...fetchedThreads,
          loanId
        ];
      }
    },
    resetActiveLoan(state: ChatState): void {
      state.activeLoanId = null;
    },
    setActiveLoan(state: ChatState, action: PayloadAction<string>): void {
      state.activeLoanId = action.payload;
    },
    setFiltersUserId(state: ChatState, action: PayloadAction<string | null>): void {
      state.filters = {
        ...state.filters,
        filterByUser: action.payload
      }
    },
    getParticipants(state: ChatState, action: PayloadAction<Participant[]>): void {
      state.participants = action.payload;
    },
    addRecipient(state: ChatState, action: PayloadAction<any>): void {
      const recipient = action.payload;
      const exists = state.recipients.find((_recipient) => _recipient.id === recipient.id);

      if (!exists) {
        state.recipients.push(recipient);
      }
    },
    removeRecipient(state: ChatState, action: PayloadAction<string>): void {
      const recipientId = action.payload;

      state.recipients = state.recipients.filter((recipient) => recipient.id !== recipientId);
    },
    setReplyToMessage(state: ChatState, action: PayloadAction<Message>): void {
      state.replyToMessage = action.payload;
    },
    setDraftMessage(state: ChatState, action: PayloadAction<Message>): void {
      state.draftMessage = action.payload;
    },
    togglePendingFetchNewMessages(state: ChatState, action: PayloadAction<string>): void {
      if (typeof state.pendingFetchNewMessages[action.payload] !== 'undefined') {
        delete state.pendingFetchNewMessages[action.payload];
      } else {
        state.pendingFetchNewMessages[action.payload] = action.payload;
      }
    },
    setQuickReplies(state: ChatState, action: PayloadAction<string[]>): void {
      state.quickReplies = action.payload;
    },
    setMessagesHTMLValue(state: ChatState, action: PayloadAction<{ byId: Record<string, string> }>): void {
      state.messagesHTML = {
        byId: {
          ...state.messagesHTML.byId,
          ...action.payload.byId
        },
        allIds: [
          ...state.messagesHTML.allIds,
          ...Object.keys(action.payload.byId)
        ],
      };
    },
    setMessageAsRead(state: ChatState, action: PayloadAction<{ loanId: string, messageId: string }>): void {
      const { loanId, messageId } = action.payload;
      const thread = state.threads.byId[loanId];
      const messageIndex = thread.messages.findIndex(message => message.id === messageId);
      thread.messages[messageIndex].readAt = Date.now();
      state.threads.byId = {
        ...state.threads.byId,
        [loanId]: {
          ...thread,
          messages: thread.messages
        }
      };
    },
    toggleMessageShowMore(state: ChatState, action: PayloadAction<{ messageId: string, showMore: boolean }>): void {
      state.showMoreStateIds = {
        ...state.showMoreStateIds,
        [action.payload.messageId]: action.payload.showMore,
      };
    },
    cleanOptimisticMessage(state: ChatState, action: PayloadAction<{ messageId: string, loanId: string }>): void {
      const { messageId, loanId } = action.payload;
      const thread = state.threads.byId[loanId];

      state.threads.byId = {
        ...state.threads.byId,
        [loanId]: {
          ...thread,
          messages: thread.messages.filter(message => message.id !== messageId)
        }
      };
    }
  }
});

export const { reducer } = chatSlice;

export const setReplyToMessage = (message?: Message): AppThunk => async (dispatch): Promise<void> => {
  dispatch(chatSlice.actions.setDraftMessage(null));
  dispatch(chatSlice.actions.setReplyToMessage(message));
  if (!!message) {
    dispatch(setOpenLoanSidebar(LoanSidebar.CHAT));
  }
}

export const setDraftMessage = (message?: Message): AppThunk => async (dispatch, getState): Promise<void> => {
  const { draftMessage, replyToMessage } = getState().chat;
  dispatch(chatSlice.actions.setReplyToMessage(null));
  dispatch(chatSlice.actions.setDraftMessage({
    ...message,
    contextId: replyToMessage?.contextId || draftMessage?.contextId
  }));
}

export const toggleMessageShowMore = (messageId: string, showMore: boolean): AppThunk => async (dispatch): Promise<void> => {
  dispatch(chatSlice.actions.toggleMessageShowMore({ messageId, showMore }));
}

export const getLoanTeamMembers = (loanId: string): AppThunk => async (dispatch, getState): Promise<void> => {
  const data = await api.getLoanTeamMembers(loanId);
  dispatch(chatSlice.actions.getLoanTeamMembers({ loanId, contacts: data }));
};

export const setMessageAsRead = ({ loanId, messageId }: { loanId: string, messageId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
  const { user } = getState().view;
  const { threads } = getState().chat;
  const { messages = [] } = threads.byId[loanId] || {};
  const message = messages.find(message => message.id === messageId);

  if (isUserTheReceiver(message, user.id)) {
    dispatch(chatSlice.actions.setMessageAsRead({ loanId, messageId }));
    api.setMessageAsRead(messageId);
  }
}

export const fetchNewMessages = (loanId: string): AppThunk => async (dispatch, getState): Promise<void> => {
  const { chat: { pendingFetchNewMessages, threads: { byId }, filters: { filterByUser } } } = getState()
  if (typeof pendingFetchNewMessages[loanId] !== 'undefined') {
    return;
  }
  const currentThread = byId[loanId];

  dispatch(chatSlice.actions.togglePendingFetchNewMessages(loanId));
  const data = await api.getLoanMessages({ loanId, page: 1, filterByUser: '' });

  // get diff between current messages and messages from api
  const diffMessages = data.filter(message => !currentThread?.messages.some(currentMessage => currentMessage.id === message.id));
  showNewMessagesToasts(diffMessages, () => {
    dispatch(setOpenLoanSidebar(LoanSidebar.CHAT));
  });
  const sanitizedMessages = await sanitizeMessages(data);
  dispatch(chatSlice.actions.fetchNewMessages({ loanId, data: sanitizedMessages }));
  dispatch(chatSlice.actions.togglePendingFetchNewMessages(loanId));
}

export const getLoanMessages = (loanId: string, page: number, filterByUser: string = ``): AppThunk => async (dispatch): Promise<void> => {
  dispatch(chatSlice.actions.loadingLoanMessages({ loanId }));
  const data: Message[] = await api.getLoanMessages({ loanId, page, filterByUser });
  const sanitizedData = await sanitizeMessages(data);
  dispatch(chatSlice.actions.getLoanMessages({ page, loanId, data: sanitizedData }));
};

export const resetActiveLoan = () => (dispatch): void => {
  dispatch(chatSlice.actions.resetActiveLoan());
};

export const setActiveLoan = (loanId: string) => (dispatch): void => {
  dispatch(chatSlice.actions.setActiveLoan(loanId));
};

export const getSubjectQuickReplies = ({ context, contextPayload }: { context: ChatMessageContext, contextPayload?: any }) => (dispatch): void => {
  let replies = [];
  switch (context) {
    case 'LOAN':
      replies = noContextReplies;
      break;
    case 'PERSON':
      replies = personReplies;
      break;
    case 'FILE_ELEMENT':
      replies = fileFormElementReplies(contextPayload?.title);
      break;
    case 'SECTION_ELEMENT':
      replies = sectionFormElementReplies;
      break;
  }
  dispatch(chatSlice.actions.setQuickReplies(replies));
};

export const setFiltersUserId = (userId: string | null) => (dispatch) => {
  dispatch(chatSlice.actions.setFiltersUserId(userId))
}

export const sendMessage = (message: APILoanMessage) => async (): Promise<void> => {
  await api.sendMessage(message);
}

export const sendLoanMessage = (message: APILoanMessage, loanId: string) => async (dispatch): Promise<void> => {
  const optimisticMessageId = generateUUID();
  const optimisticMessageList = [{
    attachments: [],
    attachmentCount: 0,
    id: optimisticMessageId,
    createdAt: Date.now(),
    body: String(message.body),
    subject: message.subject,
    preview: String(message.body),
    locked: false,
    receiverName: message.receiverName,
    senderName: message.username,
    contentType: 'text',
    senderType: 'contact',
    senderId: message.senderId,
    receiverId: message.toUserIds[0],
    isMock: true,
    readAt: Date.now(),
    contextId: message.contextId,
  } as Message]
  try {
    const sanitizedOptimisticMessages = await sanitizeMessages(optimisticMessageList);
    dispatch(chatSlice.actions.fetchNewMessages({ loanId, data: sanitizedOptimisticMessages }));
    const responseMessage = await api.sendMessage(message);
    const sanitizedResponseMessages = await sanitizeMessages([responseMessage]);
    dispatch(chatSlice.actions.fetchNewMessages({ loanId, data: sanitizedResponseMessages }));
  } finally {
    dispatch(chatSlice.actions.cleanOptimisticMessage({ messageId: optimisticMessageId, loanId }));
  }
};

// get html message body for all messages after the specified message id
export const getHtmlMessagesAfter = (messageId: string) => async (dispatch, getState): Promise<void> => {
  const { activeLoanId, threads, messagesHTML } = getState().chat;
  const thread = threads.byId[activeLoanId];
  const messages = thread.messages;
  const messageIndex = messages.findIndex(message => message.id === messageId);

  const messagesAfter = messages.slice(messageIndex + 1)
    .filter(message => messagesHTML.byId[message.id] === undefined && !!message.originalMessageContentUrl);
  const promises = messagesAfter.map(message => new Promise(async (resolve) => {
    try {
      const htmlContent = await api.getOriginalMessageAsHtml(message.id);
      resolve(htmlContent);
    } catch (error) {
      resolve('')
    }
  }));
  const htmlMessages = await Promise.all(promises);
  const byId = {}
  htmlMessages.forEach((htmlMessage, index) => {
    byId[messagesAfter[index].id] = htmlMessage;
  });

  dispatch(chatSlice.actions.setMessagesHTMLValue({ byId }));
}

// get html message body for specified message id
export const getHtmlMessageForId = (messageId: string) => async (dispatch, getState): Promise<string> => {
  const { activeLoanId, threads, messagesHTML } = getState().chat;
  const thread = threads.byId[activeLoanId];
  const messages = thread.messages;

  const message = messages.find(message => message.id === messageId);
  if (messagesHTML.byId[messageId] !== undefined) {
    return messagesHTML.byId[message.id]
  }

  const htmlContent = await api.getOriginalMessageAsHtml(messageId);
  dispatch(chatSlice.actions.setMessagesHTMLValue({ byId: { [messageId]: htmlContent } }));

  return htmlContent;
}

// state selectors
export const loansThreadsSelector = (state: RootState) => state.chat.threads.byId;

export const replyToMessageSelector = (state: RootState) => state.chat.replyToMessage;

export const draftMessageSelector = (state: RootState) => state.chat.draftMessage;

export const selectLoanThreadById = (loanId: string) => createSelector((state: RootState) => state.chat.threads.byId, byId => {
  return byId[loanId];
})

export const loanThreadSelector = createSelector((state: RootState): any => state.chat, (chatState) => {
  const { threads, activeLoanId } = chatState;
  const thread = threads.byId[activeLoanId];

  if (thread) {
    return thread;
  }

  return {
    id: null,
    messages: [],
    isLoading: true,
    hasMoreMessages: true,
    participants: [],
  };
});

export const quickRepliesSelector = (state: RootState) => state.chat.quickReplies;

export const allTeamMembersSelector = createSelector((state: RootState) => state.chat.contacts, stateContacts => {
  const loansIds = Object.keys(stateContacts.byId)

  const contacts: Contact[] = loansIds.reduce((allContacts, loanId) => [
    ...allContacts,
    ...stateContacts.byId[loanId].filter(contact => !allContacts.some(allContact => contact.id !== allContact.id))], [])

  return contacts
})

export const loanTeamMembersSelector = createSelector([(state: RootState) => state.chat.contacts, (state: RootState) => state.chat.activeLoanId], (contacts, loanId) => {
  const teamMembers = contacts.byId[loanId];
  if (teamMembers) {
    return teamMembers;
  }
  return [];
});

export const messageShowMoreSelector = (messageId: string) => createSelector([(state: RootState): any => state.chat, (state: RootState): any => state.ui.messagesExpanded], (chatState, messagesExpanded) => {
  const showMoreState = chatState.showMoreStateIds[messageId];
  if (typeof showMoreState === 'undefined') {
    return messagesExpanded;
  } else {
    return showMoreState;
  }
});

export const contextLoanUnreadMessagesCountSelector = createSelector([(state: RootState): any => state.chat, (state: RootState): any => state.view.user], (chatState, user) => {
  const { threads, activeLoanId } = chatState;
  const thread = threads.byId[activeLoanId];

  if (thread) {
    return thread.messages.filter(message => !message.readAt && message.senderId !== user.id && message.receiverId === user.id).length
  }

  return 0;
});

export default chatSlice;
