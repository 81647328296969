import { type PayloadAction,createSlice } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store';
import { countWords } from 'src/utils/count-words';

import type { SnackBarMessage } from '../types/ui';

interface SnackbarState {
    isOpen: boolean;
    message: string;
    autoHideDuration: number;
    severity: 'error' | 'success' | 'info' | 'warning'
}

const initialState: SnackbarState = {
    isOpen: false,
    autoHideDuration: 6000,
    message: '',
    severity: 'info'
};

const slice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        hideSnackbar(state: SnackbarState, action: PayloadAction<Pick<SnackBarMessage, 'text' | 'severity'>>): void {
            state.isOpen = false
            state.message = ''
            state.severity = 'info'
        },
        showSnackbar(state: SnackbarState, action: PayloadAction<Pick<SnackBarMessage, 'text' | 'severity'> & { durationInMilliseconds: number }>): void {
            state.autoHideDuration = action.payload.durationInMilliseconds
            state.isOpen = true;
            state.message = action.payload.text;
            state.severity = action.payload.severity;
        }
    }
});

export const { reducer } = slice;
// Math.ceil(countWords(text) / 250)
export const showSnackbar = (item: Pick<SnackBarMessage, 'text' | 'severity'>): AppThunk => async (dispatch): Promise<void> => {
    const durationInMilliseconds = countWords(item.text) / 250 * 60 * 1000 * 1.5

    dispatch(slice.actions.showSnackbar({ ...item, durationInMilliseconds }));
};

export const hideSnackbar = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.hideSnackbar());
};

export const snackbarSelector = (state: RootState) => state.snackbar;

export default slice;
