import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { api, APIPerson, UploadDriverLicenseParams } from 'src/api'
import { InviteToLoanDTO, PersonCreateDto, PersonResponseDto, PersonUpdateDto, USDriversLicense } from 'src/backend';
import { ActionStatus } from 'src/constants/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import type { IFeatures } from 'src/types/person';
import type { Borrower, ConsentStatus, IDriverLicenseImage, IUserDriverLicense } from 'src/types/view';
import { API_STATUS } from 'src/types/view'
import { toast } from 'src/utils/toast';

import { getLoans } from './loan-manager';
import viewSlice from './view'

interface Person {
    personId: string;
    images?: IDriverLicenseImage
    loanId?: string
}

interface PersonState {
    personProfiles: Record<string, PersonResponseDto>,
    features: IFeatures,
    persons: Person[],
    createPersonsErrors: {},
    createPersonStatus: API_STATUS,
    createdPerson: Person | null,
    uploadUrls: IDriverLicenseImage,
    driverLicenseImages: IDriverLicenseImage,
    inviteRequestStatus: Record<string, boolean>;
}

const initialState: PersonState = {
    personProfiles: {},
    persons: [],
    features: [],
    createPersonsErrors: {},
    createPersonStatus: 'idle',
    createdPerson: null,
    inviteRequestStatus: {},
    uploadUrls: {
        front: null,
        back: null,
        backStatus: 'idle',
        frontStatus: 'idle'
    },
    driverLicenseImages: {
        front: null,
        back: null,
        backStatus: 'idle',
        frontStatus: 'idle',
        backUploadStatus: 'idle',
        frontUploadStatus: 'idle'
    },
};

const slice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        createPersonStateChange(state: PersonState, action: PayloadAction<API_STATUS>): void {
            state.createPersonStatus = action.payload
        },
        createPersonErrors(state: PersonState, action: PayloadAction<{ errors: { [x: string]: any } }>): void {
            state.createPersonsErrors = action.payload.errors
        },
        createdPerson(state: PersonState, action: PayloadAction<Person>): void {
            state.createdPerson = action.payload
        },
        getDriverLicenseImages(state: PersonState, action: PayloadAction<Person>): void {
            const personIndex = state.persons.findIndex((person) => person.personId === action.payload.personId)
            if (personIndex === -1) {
                state.persons = [
                    ...state.persons,
                    action.payload
                ]
            } else {
                state.persons[personIndex] = action.payload
            }
            state.driverLicenseImages = action.payload.images
        },
        uploadDriverLicense(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.driverLicenseImages = {
                ...state.driverLicenseImages,
                ...action.payload
            }
        },
        getDriverLicenseImagesStateChange(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.driverLicenseImages = {
                ...state.driverLicenseImages,
                ...action.payload
            }
        },
        setPersonFeatures(state: PersonState, action: PayloadAction<IFeatures>): void {
            state.features = action.payload
        },
        getUploadDriverLicenseUrls(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.uploadUrls = action.payload
        },
        getPersonProfiles(state: PersonState, action: PayloadAction<PersonResponseDto>): void {
            state.personProfiles = {
                ...state.personProfiles,
                [action.payload.id]: action.payload
            }
        },
        setInviteRequestStatus(state: PersonState, action: PayloadAction<{ personId: string, status: boolean }>): void {
            state.inviteRequestStatus = {
                ...state.inviteRequestStatus,
                [action.payload.personId]: action.payload.status
            }
        }
    }
});

export const { reducer } = slice;

export const getDriverLicenseImages = (personId: string, type?: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    if (typeof personId === 'undefined' || !personId) {
        return
    }
    if (type) {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            ...(type === 'front' ? { frontStatus: 'loading' } : { backStatus: 'loading' })
        }))
    } else {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            frontStatus: 'loading',
            backStatus: 'loading'
        }))
    }
    try {
        const individual = await api.getIndividualFromPerson(personId, { id: personId });
        const data = await api.getADriversLicense(individual.id);
        let front = null, back = null;
        if (data?.dlBack?.id) {
            back = await api.getDocumentDownloadUrl(data.dlBack.id);
        }
        if (data?.dlFront?.id) {
            front = await api.getDocumentDownloadUrl(data.dlFront.id);
        }
        dispatch(slice.actions.getDriverLicenseImages({
            images: {
                front,
                back,
            },
            personId
        }));
    } catch (error) {
    }
    if (type) {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            ...(type === 'front' ? { frontStatus: 'idle' } : { backStatus: 'idle' })
        }))
    } else {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            frontStatus: 'idle',
            backStatus: 'idle'
        }))
    }
};

export const updateDriverLicense = (personId: string, details: USDriversLicense): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.createPersonStateChange('loading'))

    const isSuccessfully = await api.postPersonDriverLicenseInfo(personId, details)

    if (isSuccessfully) {
        dispatch(slice.actions.createPersonStateChange('success'))
    } else {
        dispatch(slice.actions.createPersonStateChange('error'))
    }
}
export const postPersonDriverLicenseInfo = (personId: string, details: USDriversLicense): AppThunk => async (dispatch): Promise<void> => {
    dispatch(updateDriverLicense(personId, details))
}

export const updatePersonInfo = (person: PersonUpdateDto): AppThunkPromise<boolean> => async (dispatch): Promise<boolean> => {
    dispatch(slice.actions.createPersonStateChange('loading'))
    dispatch(slice.actions.createPersonErrors({ errors: {} }))
    const data = await api.updatePersonInfo(person)

    if (typeof data.errors === 'undefined') {
        dispatch(slice.actions.createPersonStateChange('success'))
        return true;
    } else {
        dispatch(slice.actions.createPersonErrors({
            errors: {
                ...data.errors,
                mobilePhone: {
                    value: data?.errors?.mobilePhone ?? '',
                }
            }
        }))
        dispatch(slice.actions.createPersonStateChange('error'));
        return false;
    }
}

export const reeschedulePersonQuickFlagReport = (loanId: string, personId: string): AppThunkPromise<void> => async (): Promise<void> => {
    await api.reeschedulePersonQuickFlagReport(loanId, personId)
}

export const setPersonFeatures = (features: IFeatures): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setPersonFeatures(features))
}

export const postPersonSoftCreditPull = (data: { signature: string, consent: ConsentStatus, personId: string, ssn: string }): AppThunkPromise => async (dispatch, getState): Promise<any> => {
    const loans = await api.getLoans({
        page: 1
    });
    // dedupe list of loans by lender id
    const dedupeLoans = loans.content.filter((loan, index, self) => {
        return index === self.findIndex((l) => l.lender.id === loan.lender.id)
    });
    dispatch(slice.actions.createPersonStateChange('loading'))
    const promises = [];
    dedupeLoans.forEach((loan) => {
        promises.push(api.postPersonSoftCreditPull({
            ...data,
            loanId: loan.id
        }));
    });
    await Promise.all(promises);
    dispatch(slice.actions.createPersonStateChange('success'))
}

export const postPersonIrsConsent = (data: { signature: string, consent: ConsentStatus, personId: string, ssn: string }): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.createPersonStateChange('loading'))
    await api.postPersonIrsConsent(data)
    dispatch(slice.actions.createPersonStateChange('success'))
}

export const setUploadDriverLicenseLoadingState = (type: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    const uploadState = {
        ...(type === 'front' ? {
            frontUploadStatus: ActionStatus.loading,
            front: ''
        } : {
            backUploadStatus: ActionStatus.loading,
            back: ''
        })
    }
    dispatch(slice.actions.uploadDriverLicense(uploadState));
};

export const setUploadDriverLicenseIdleState = (type: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    const uploadState = {
        ...(type === 'front' ? {
            frontUploadStatus: ActionStatus.idle,
            front: ''
        } : {
            backUploadStatus: ActionStatus.idle,
            back: ''
        })
    }
    dispatch(slice.actions.uploadDriverLicense(uploadState));
};

export const uploadDriverLicense = ({ personId, file, type, dto }: UploadDriverLicenseParams): AppThunk => async (dispatch): Promise<void> => {
    const individual = await api.getIndividualFromPerson(personId, { id: personId });
    dispatch(setUploadDriverLicenseLoadingState(type));
    let dlBackDocumentId = null;
    let dlFrontDocumentId = null;
    try {
        const driversLicense = await api.getADriversLicense(individual.id);
        dlBackDocumentId = driversLicense?.dlBack?.id ?? null;
        dlFrontDocumentId = driversLicense?.dlFront?.id ?? null
    } catch (e) { }
    const data = await api.uploadDriverLicenseImage({
        individualId: individual.id,
        dlBackDocumentId,
        dlFrontDocumentId,
        file,
        type,
        dto
    });
    dispatch(slice.actions.uploadDriverLicense(data));
    dispatch(getDriverLicenseImages(personId, type))
};

export const createPerson = ({ person, loanId }: { person: PersonCreateDto, loanId?: string }): AppThunkPromise<PersonResponseDto> => async (dispatch): Promise<PersonResponseDto> => {
    const { data, errors } = await api.createPerson(person, loanId);

    if (data?.id) {
        dispatch(getLoans({
            page: 1
        }));
        toast({
            content: 'Person added to loan',
            type: ActionStatus.success
        });
        dispatch(slice.actions.createdPerson({ personId: data.id, loanId }));
        dispatch(slice.actions.createPersonStateChange('success'));

        return data;
    } else {
        dispatch(slice.actions.createPersonErrors({
            errors: errors
        }))
        dispatch(slice.actions.createPersonStateChange('error'))
    }
}

export const resetPersonErrors = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.createPersonErrors({ errors: {} }))
}

export const createdPerson = (person: Person | null): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.createdPerson(person))
    if (person === null) {
        dispatch(slice.actions.createPersonStateChange('idle'))
    }
}

export const createPersonStateChange = (state: API_STATUS): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.createPersonStateChange(state))
}

export const invitePersonToLoan = (inviteRequest: InviteToLoanDTO): AppThunkPromise => async (dispatch): Promise<any> => {
    dispatch(slice.actions.setInviteRequestStatus({
        personId: inviteRequest.userId,
        status: true,
    }));
    try {
        const isSuccessful = await api.inviteUserToLoan(inviteRequest.loanId, inviteRequest);
        if (isSuccessful) {
            dispatch(viewSlice.actions.getView())
            toast({
                content: `Invitation Sent!`,
                type: ActionStatus.success
            });
        }
    } catch (error) {
    } finally {
        dispatch(slice.actions.setInviteRequestStatus({
            personId: inviteRequest.userId,
            status: false,
        }));
    }


}

export const getPerson = (personId: string): AppThunk => async (dispatch): Promise<void> => {
    const person = await api.getPerson(personId);
    dispatch(slice.actions.getPersonProfiles(person));
}

export const updatePerson = ({ id, person, loanId }: { id: string, person: Partial<PersonResponseDto>, loanId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    try {
        await api.updatePerson(id, person);
        dispatch(getPerson(id));
        dispatch(slice.actions.createdPerson({ personId: id, loanId }));
        toast({
            content: 'Principal details updated',
            type: ActionStatus.success
        });
        dispatch(slice.actions.createPersonStateChange('success'));
    } catch (error) { }

}

export const getPersonFromIndividual = (individualId: string): AppThunkPromise<PersonResponseDto> => async (dispatch): Promise<PersonResponseDto> => {
    const person = await api.getPersonFromIndividual(individualId, { id: individualId });
    dispatch(slice.actions.getPersonProfiles(person));
    return person;
}

export const personSelector = (state: RootState) => state.person.persons;

export const singlePersonSelector = (id: string) => createSelector(
    [(state: RootState) => state.person.personProfiles],
    (profiles) => profiles[id]);

export const createPersonStatusSelector = (state: RootState) => state.person.createPersonStatus;
export const createdPersonSelector = (state: RootState) => state.person.createdPerson;
export const createPersonErrorsSelector = (state: RootState) => state.person.createPersonsErrors;
export const uploadUrlsSelector = (state: RootState) => state.person.uploadUrls
export const driverLicenseImagesSelector = (state: RootState) => state.person.driverLicenseImages
export const selectPersonInviteRequestStatus = (personId: string) => (state: RootState) => Boolean(state.person.inviteRequestStatus[personId]);

export default slice;
