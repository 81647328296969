import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppUserDTO2, AppUserSignupRequestDto, LinkActionInviteToLoan } from "src/backend";
import { AppThunkPromise, RootState } from "src/store";
import { UserPreference } from "src/types/user";

import { api } from "../api";
import {
    setAgreedToTOS,
    setCamScanMode,
    setDadZipFileHandling,
    setFileElementUploader,
    setHiddenPhases,
    setIsPreviewMode,
    setSendEmailAddRemoveUser,
    setSendEmailAppMessage,
    setSendEmailLoanStatus,
    setSendTextAddRemoveUser,
    setSendTextAppMessage,
    setSendTextLoanStatus,
    setTimezoneId,
    setUseAlternativeFilePreviewer,
} from "./ui";


interface UserState {
    pendingInvites: LinkActionInviteToLoan[]
    preference: UserPreference
}

const initialState: UserState = {
    pendingInvites: [],
    preference: {
        fileElementUploader: "FILE",
        previewMode: false,
        camScanMode: "COLOR",
        timezoneId: "America/New_York",
        useAlternativeFilePreviewer: false,
        dadZipFileHandling: "ASK",
        agreedToTOS: false,
        hiddenLoanStatuses: "",
        formElementNavigator: null,
        formElementTreeMapDepth: null,
        formElementTreeMapHideFiles: null,
        sendEmailAddRemoveUser: false,
        sendEmailAppMessage: false,
        sendEmailLoanStatus: false,
        sendTextAddRemoveUser: false,
        sendTextAppMessage: false,
        sendTextLoanStatus: false,
    }
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setPendingInvites: (state, action: PayloadAction<LinkActionInviteToLoan[]>) => {
            state.pendingInvites = action.payload;
        },
        setPreference: (state, action: PayloadAction<UserPreference>) => {
            state.preference = action.payload;
        }
    }
})

export const AddUser = (body: AppUserSignupRequestDto): AppThunkPromise<AppUserDTO2> => async (): Promise<AppUserDTO2> => {
    const user = await api.addUser(body);
    return user;
};

export const editBorrowUser = (loanId: string, body: AppUserDTO2): AppThunkPromise<AppUserDTO2> => async (): Promise<AppUserDTO2> => {
    const user = await api.editBorrowUser(loanId, body.id, body);
    return user;
};

export const getPendingInvites = (): AppThunkPromise<LinkActionInviteToLoan[]> => async (dispatch): Promise<LinkActionInviteToLoan[]> => {
    const invites = await api.getPendingInvites();
    dispatch(actions.setPendingInvites(invites));
    return invites;
};

export const updateUserPreferences = (preferences: Partial<UserPreference>): AppThunkPromise<void> => async (dispatch, getState) => {
    const { [userSlice.name]: { preference: statePreference } } = getState();
    await api.putUserPreferences({
        ...statePreference,
        ...preferences
    });
}

export const setUserPreferences = (preferences: UserPreference): AppThunkPromise<any> => async (dispatch): Promise<any> => {
    dispatch(actions.setPreference(preferences));
    dispatch(setFileElementUploader(preferences.fileElementUploader));
    dispatch(setIsPreviewMode(preferences.previewMode));
    dispatch(setCamScanMode(preferences.camScanMode));
    dispatch(setTimezoneId(preferences.timezoneId));
    dispatch(setUseAlternativeFilePreviewer(preferences.useAlternativeFilePreviewer));
    dispatch(setDadZipFileHandling((preferences.dadZipFileHandling)));
    dispatch(setAgreedToTOS(preferences.agreedToTOS));
    dispatch(setHiddenPhases(preferences.hiddenLoanStatuses !== "" ? preferences.hiddenLoanStatuses?.split(",") : []));
    dispatch(setSendEmailAddRemoveUser(preferences.sendEmailAddRemoveUser));
    dispatch(setSendEmailAppMessage(preferences.sendEmailAppMessage));
    dispatch(setSendEmailLoanStatus(preferences.sendEmailLoanStatus));
    dispatch(setSendTextAddRemoveUser(preferences.sendTextAddRemoveUser));
    dispatch(setSendTextAppMessage(preferences.sendTextAppMessage));
    dispatch(setSendTextLoanStatus(preferences.sendTextLoanStatus));
};

export const selectPendingInvites = (state: RootState): LinkActionInviteToLoan[] => state.user.pendingInvites;

export const selectUserPreference = (state: RootState): UserPreference => state[userSlice.name].preference;

export const { reducer, actions } = userSlice;
