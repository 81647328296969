import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyListDto, CreateUpdateAppUserDto, OnboardCompany, SecurityResponseDto } from "src/backend";
import { ActionStatus } from 'src/constants/ui';
import { ValueOf } from "src/types/common";

import { api, get } from "../api";
import { RootState } from "../store";

const sortCompaniesAlphabetically = (companies: CompanyListDto[]) => companies.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))


interface AdminState {
    companies: CompanyListDto[];
    companiesState: ValueOf<keyof typeof ActionStatus>;
}

const initialState: AdminState = {
    companies: [],
    companiesState: ActionStatus.idle
}

const slice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<ValueOf<keyof typeof ActionStatus>>) {
            state.companiesState = action.payload;
        },
        getCompanies(state, action: PayloadAction<CompanyListDto[]>) {
            state.companies = action.payload;
            state.companiesState = ActionStatus.idle;
        }
    }
})

export const fetchCompanies = ({ refresh }: { refresh: boolean } = { refresh: false }) => async (dispatch, getState: () => RootState): Promise<void> => {
    const { admin: { companies } } = getState();
    if (companies.length === 0 || refresh) {
        dispatch(slice.actions.setLoading(ActionStatus.loading));
        try {
            const companiesResponse = await get<CompanyListDto[]>("/v1/lenders/companies");
            if (Array.isArray(companiesResponse)) {
                dispatch(slice.actions.getCompanies(sortCompaniesAlphabetically(companiesResponse)));
            }
            dispatch(slice.actions.setLoading(ActionStatus.idle));
        } catch (error) {
            dispatch(slice.actions.setLoading(ActionStatus.error));
        }
    }
}

export const updateAppUser = (appUser: CreateUpdateAppUserDto) => async (dispatch, getState: () => RootState): Promise<SecurityResponseDto> => {
    const user = await api.updateAppUser(appUser);
    return user;
}

export const updateCompany = (companyId:string, company: OnboardCompany) => async (dispatch, getState: () => RootState): Promise<void> => {
    const companyResponse = await api.updateCompany(companyId, company);
    return companyResponse;
}

export const { reducer, actions } = slice;

export const adminSelector = (state: any) => {
    return state.admin as AdminState
}

export const companiesSelector = createSelector(
    adminSelector,
    (admin) => admin.companies
);

export const companiesStateSelector = createSelector(
    adminSelector,
    (admin) => admin.companiesState
);
