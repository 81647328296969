import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { api } from 'src/api'
import { Card, ViewType } from 'src/backend';
import { CardStatus, CardType } from 'src/constants/loan';
import { ANONYMOUS_VIEW, LENDER_VIEW, PRINCIPAL_VIEW } from 'src/constants/person';
import { ActionStatus, UNAUTHORIZED_ERROR_STATUS } from 'src/constants/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { Loan } from 'src/types/loan';
import type { API_STATUS, IViewFilterData, IViewState, LoanPackageType } from 'src/types/view';
import { IViewData } from 'src/types/view';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';


export const initialState: IViewState = {
    pendingGetView: false,
    currentName: "Test User",
    applicant: null,
    employer: null,
    viewType: null,
    availableView: null,
    status: ActionStatus.idle,
    cardsPristine: [],
    cards: [],
    hasProcessed: false,
    filters: {},
    user: null,
    currentCard: null,
    currentLoanId: null,
    loggedInUserId: null
};

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        getView(state: IViewState, action: PayloadAction<IViewData>): void {
            if (state.cards?.length === 0) {
                state.status = ActionStatus.loading;
            }
            if (action.payload) {
                const enhancedCards = action.payload.cards.map((card: Card<any>) => {
                    return {
                        ...card,
                        id: card.body?.id
                    }
                })
                const summaries = action.payload.cards.find(card => card.cardType === CardType.LOAN_SUMMARIES)
                if (summaries) {
                    summaries.cards.forEach((card: Card<any>) => {
                        enhancedCards.push({
                            ...card,
                            id: card.body?.id
                        })
                    })

                }

                let viewType = null
                const isLender = action.payload.user.employer !== null && typeof action.payload.user.employer !== 'undefined'
                const isApplicant = (action.payload.user.person !== null && typeof action.payload.user.person !== 'undefined')
                state.employer = action.payload.user.employer;
                if (isLender) {
                    const { user: { employer } } = action.payload
                    state.currentName = employer.name;
                    viewType = LENDER_VIEW;
                } else if (isApplicant) {
                    const { user: { person } } = action.payload
                    state.applicant = person;
                    state.loggedInUserId = action.payload.user.id;
                    state.currentName = person?.name;
                    enhancedCards.forEach((card: Card<any>) => {
                        if (card.cardType === CardType.ONBOARDING) {
                            const onboardingCard = card.cards.filter(f => f.cardType === CardType.CONGRATULATIONS && f.status === CardStatus.PROCESSED);
                            if (onboardingCard.length > 0 && !state.hasProcessed) {
                                state.hasProcessed = true;
                            }
                        }
                    });
                    viewType = PRINCIPAL_VIEW;
                } else {
                    viewType = ANONYMOUS_VIEW;
                }
                // set user
                state.user = action.payload.user

                if (isLender && isApplicant) {
                    // TODO we need a way to determine if the user is a lender and principal
                    // state.availableView = LENDER_PRINCIPAL_VIEW;
                } else if (isLender) {
                    state.availableView = LENDER_VIEW;
                } else if (isApplicant) {
                    state.availableView = PRINCIPAL_VIEW;
                } else {
                    state.availableView = ANONYMOUS_VIEW;
                }

                if (!state.viewType) {
                    state.viewType = viewType
                }

                state.cards = [...enhancedCards.filter(card => {
                    let hasStatus = true
                    let isActive = true
                    if (typeof state.filters.active !== 'undefined') {
                        isActive = Boolean(card.active) === state.filters.active
                    }
                    if (typeof state.filters.status !== 'undefined' && state.filters.status.length > 0) {
                        hasStatus = state.filters.status.includes(card.status)
                    }

                    return hasStatus && isActive
                })]

                state.cardsPristine = enhancedCards
                state.status = ActionStatus.idle;
                if (state.currentLoanId) {
                    const card = state.cardsPristine.find((card) => card.body.id === state.currentLoanId)
                    state.currentCard = card
                }
            }
        },
        updateState(state: IViewState, action: PayloadAction<API_STATUS>): void {
            state.status = action.payload
        },
        setSearchFilters(state: IViewState, action: PayloadAction<IViewFilterData>): void {
            state.filters = {
                ...state.filters,
                ...action.payload
            }
            state.cards = state.cardsPristine.filter(card => {
                let hasStatus = true
                let isActive = true
                if (typeof action.payload.active !== 'undefined') {
                    isActive = Boolean(card.active) === action.payload.active
                }
                if (typeof action.payload.status !== 'undefined' && action.payload.status.length > 0) {
                    hasStatus = action.payload.status.includes(card.status)
                }

                return hasStatus && isActive
            })
        },
        setCurrentCard(state: IViewState, action: PayloadAction<string>): void {
            const card = state.cardsPristine.find((card) => String(card.body?.id) === String(action.payload))
            state.currentLoanId = action.payload
            state.currentCard = card
        },
        setViewType(state: IViewState, action: PayloadAction<ViewType>): void {
            state.viewType = action.payload
        },
        setPendingGetView(state: IViewState, action: PayloadAction<boolean>): void {
            state.pendingGetView = action.payload
        }
    }
});

export const { reducer } = viewSlice;

export const getView = ({ viewType }: { viewType?: ViewType } = {}): AppThunkPromise<IViewData> => async (dispatch, getState): Promise<IViewData> => {
    const { view: { pendingGetView } } = getState();
    if (pendingGetView) {
        return;
    }

    try {
        dispatch(viewSlice.actions.setPendingGetView(true));
        let data: IViewData
        if (viewType) {
            data = await api.getView({ viewType });
        } else {
            data = await api.getView();
        }
        dispatch(viewSlice.actions.getView(data));
        return data;
    } catch (err) {
        // TODO BM message to myself, this is an ugly quick fix should be improved
        if (err?.status === UNAUTHORIZED_ERROR_STATUS || err?.message === 'Not signed in') {
            dispatch(viewSlice.actions.setViewType(ANONYMOUS_VIEW));
        }
    } finally {
        dispatch(viewSlice.actions.setPendingGetView(false));
    }
};

export const setViewType = (viewType: ViewType): AppThunk => (dispatch) => {
    dispatch(viewSlice.actions.setViewType(viewType))
}

export const setSearchFilters = (data: IViewFilterData): AppThunk => async (dispatch): Promise<void> => {
    dispatch(viewSlice.actions.setSearchFilters(data));
};

export const updateLoanStatus = ({ loanId, status }: { status: string, loanId: string }): AppThunkPromise => async (): Promise<boolean | Loan> => {
    try {
        const data = await api.updateLoanStatus({ loanId, status });
        return data;
    } catch (error) {
        notifyBugTracker(error);
    }
    return false;
};

// initiate generating loan package zip file
export const postCreateLoanPackage = ({ loanId }: { loanId: string }): AppThunk => async (_, getState): Promise<void> => {

    await api.postCreateLoanPackage({ loanId })
}
// initiate generating loan package zip file
export const getLoanPackage = ({ loanId, type }: { loanId: string, type: LoanPackageType }): AppThunk => async (_, getState): Promise<void> => {

    await api.getLoanPackage({ loanId, type })
}

export const setCurrentCard = (id: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(viewSlice.actions.setCurrentCard(id));
};

export const applicantSelector = createSelector((state: RootState) => state.view.applicant, (applicant) => applicant);

export const lenderSelector = (state: RootState) => {
    if (state.view.employer) {
        return state.view.employer
    }
    const loansKeys = Object.keys(state.loan.loans);

    return state.loan.loans[loansKeys?.[0]]?.lender
};

export const lenderIdSelector = (state: RootState) => {
    if (state.view.employer) {
        return state.view.employer.id
    }
    const loansKeys = Object.keys(state.loan.loans);

    return state.loan.loans[loansKeys?.[0]]?.lender?.id
};

export const availableViewSelector = (state: RootState) => state.view.availableView;
export const viewTypeSelector = createSelector((state: RootState) => state.view.viewType, viewType => viewType);
export const viewStatusSelector = (state: RootState) => state.view.status;
export const cardsSelector = (state: RootState) => state.view.cards;
export const userSelector = createSelector([(state: RootState) => state.view.user], user => user);
export const isUserALenderSelector = (state: RootState) => state.view.viewType === LENDER_VIEW;

export const currentCardSelector = createSelector(
    [(state: RootState) => state.view.currentCard],
    (currentCard) => currentCard
);

export const selectCurrentLoanOnboardingList = createSelector(
    (state: RootState) => state.view.currentCard,
    (currentCard) => currentCard ? currentCard.cards.filter(card => card.cardType === CardType.ONBOARDING) : []
);

export const selectLoanSummaries = createSelector(
    (state: RootState) => state.view.cards,
    (cards) => cards.filter(card => card.cardType === CardType.LOAN_SUMMARY)
);

export const currentName = (state: RootState) => state.view.currentName;

export default viewSlice;
