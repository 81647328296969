// a function that accepts a file 
// convert the file to jpeg if it's heic / tiff / png / gif

import { convertHeicToJpg } from "./convert-heic-to-jpg";
import { convertImageFileToJpegFile } from "./convert-image-file-to-jpg-file";
import { convertTiffFileToJpegFile } from "./convert-tiff-to-jpeg";
import { getFileExtension } from "./get-file-extension";

// and returns a promise that resolves to another file
export const processFileForUpload = async (file: File, pdfTronKey: string): Promise<File> => {
    try {
        const fileExtension = getFileExtension(file);
        switch (fileExtension) {
            case "heic":
                return await convertHeicToJpg(file);
            case 'bmp':
            case 'png':
            case 'webp':
            case 'gif':
                return await convertImageFileToJpegFile(file);
            case 'tiff': {
                return await convertTiffFileToJpegFile(file, pdfTronKey);
            }
        }
        return file;
    } catch (error) {
        return file;
    }
}