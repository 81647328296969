import { ZipStatus } from "src/constants/common";
import { Type, ZIP_IGNORE_FILES } from 'src/constants/form-element';
import { ZIP_STATUS, ZipFileElement } from "src/types/common";

export const entriesToFilesTree = async (filesList: any[]) => {
    const mime = (await import('mime')).default;
    const zip = (await import("@zip.js/zip.js"));

    const result: ZipFileElement[] = [];
    const tree = { result };

    for (const entry of filesList) {
        // get entry blob by using BlobWriter
        const blob = await entry.getData(
            // writer
            new zip.BlobWriter());
        // get mime type from file extension
        const sections = entry.filename.split('/');
        sections.reduce((r, name, currentIndex) => {
            const file = new File([blob], name, { type: mime.getType(name) });
            if (!r[name]) {
                r[name] = { result: [] };
                r.result.push({ name, ...(currentIndex < sections.length - 1 ? { children: r[name].result, type: Type.SECTION } : { file, type: Type.FILE }) })
            }

            return r[name];
        }, tree);
    }

    return result;
}
export const entriesToFiles = async (filesList: any[]): Promise<File[]> => {
    const mime = (await import('mime')).default;
    const zip = (await import("@zip.js/zip.js"));

    const files: File[] = [];

    for (const entry of filesList) {
        // get entry blob by using BlobWriter
        const blob = await entry.getData(
            // writer
            new zip.BlobWriter());
        // get mime type from file extension
        const sections = entry.filename.split('/');
        const name = sections[sections.length - 1];
        files.push(new File([blob], name, { type: mime.getType(name) }));
    }

    return files;
}

export async function getFoldersAndFiles(file: File, password?: string): Promise<{
    tree: ZipFileElement[],
    files: File[]
}> {
    const zip = (await import("@zip.js/zip.js"));
    // convert file to array buffer
    const fileArrayBuffer = await file.arrayBuffer();
    // get blob from file
    const fileBlob = new Blob([fileArrayBuffer], { type: file.type });
    // create a BlobReader to read with a ZipReader the zip from a Blob object
    const reader = new zip.ZipReader(new zip.BlobReader(fileBlob), { password });
    // get all entries from the zip
    const entries = await reader.getEntries();
    // remove hidden files and folders entries
    const filteredFiles = entries.filter(entry => {
        const ignore = ZIP_IGNORE_FILES.some(ignoreFile => entry.filename.includes(ignoreFile));
        const { length, [length - 1]: last } = entry.filename.split('/');
        return !entry.directory && !ignore && !last?.startsWith('.');
    });
    const files = await entriesToFiles(filteredFiles);
    const result = await entriesToFilesTree(filteredFiles);
    reader.close();
    return { tree: result, files };
}

export const getZipStatus = async (file: File): Promise<ZIP_STATUS> => {
    const zip = (await import("@zip.js/zip.js"));
    // convert file to array buffer
    const fileArrayBuffer = await file.arrayBuffer();
    // get blob from file
    const fileBlob = new Blob([fileArrayBuffer], { type: file.type });
    // create a BlobReader to read with a ZipReader the zip from a Blob object
    const reader = new zip.ZipReader(new zip.BlobReader(fileBlob));
    try {
        // get all entries from the zip
        const entries = await reader.getEntries();
        const encrypted = entries.some(entry => entry.encrypted);
        if (encrypted) {
            return ZipStatus.ENCRYPTED;
        }
        return ZipStatus.SUCCESS;
    } catch (error) {
        if ([zip.ERR_ENCRYPTED, zip.ERR_INVALID_PASSWORD].includes(error.message)) {
            return ZipStatus.ENCRYPTED;
        }
        return ZipStatus.ERRORED;
    } finally {
        await reader.close();
    }
}