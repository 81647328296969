
import { getAssetPath } from './url/get-asset-path';

// convert tiff/doc/docx to pdf using Pdftron
export const convertTiffFileToJpegFile = async (file: File, pdftronKey: string): Promise<File> => {
    return new Promise(async (resolve: (file: File) => void, reject) => {
        const viewerDiv = document.createElement('div');
        viewerDiv.style.display = 'none';
        viewerDiv.style.position = 'absolute';
        viewerDiv.style.visibility = 'hidden';
        document.body.appendChild(viewerDiv);
        try {
            const WebViewer = (await import('@pdftron/webviewer')).default;

            const instance = await WebViewer(
                {
                    licenseKey: pdftronKey,
                    isReadOnly: false,
                    fullAPI: true,
                    disableLogs: true,
                    loadAsPDF: true,
                    path: getAssetPath('static/webviewer10'),
                    enableAnnotations: false,
                },
                viewerDiv,
            );
            const { PDFNet, documentViewer } = instance.Core;
            await PDFNet.initialize();
            await instance.UI.loadDocument(file, { filename: file.name });

            documentViewer.addEventListener('documentLoaded', async () => {
                const doc = await documentViewer.getDocument();
                const data = await doc.getFileData();
                const blob = new Blob([data], { type: 'application/pdf' });
                const finalFile = new File([blob], `${file.name.replaceAll(".doc", "")}.pdf`, { type: 'application/pdf' });
                document.body.removeChild(viewerDiv);
                resolve(finalFile)
            }, {
                once: true,
            });

        } catch (error) {
            document.body.removeChild(viewerDiv);
            reject(error);
        }
    });
}