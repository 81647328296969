import { createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { FORM_ELEMENT_UPLOADER } from 'src/components/form-elements/use-pick-form-element-uploader';
import { ZipFileAction } from 'src/constants/common';
import { LoansKanbanFilter } from 'src/constants/loan';
import { FormElementNavigator, LoanOverviewView, UNAUTHORIZED_ERROR_STATUS } from 'src/constants/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { ZIP_FILE_ACTION } from 'src/types/common';
import { CAM_SCAN_MODE, TLoanOverviewView, TLoanSidebar } from 'src/types/ui';
import { intervalManager } from 'src/utils';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

type LoansFilterType = keyof typeof LoansKanbanFilter;
interface UiState {
    settingsDrawerVisible?: boolean,
    isDraggingFiles?: boolean,
    previewMode?: boolean,
    connectionError?: boolean,
    closeChatAfterAction?: boolean,
    useAlternativeFilePreviewer?: boolean,
    fileElementUploader?: FORM_ELEMENT_UPLOADER,
    camScanMode: CAM_SCAN_MODE,
    dadZipFileHandling: ZIP_FILE_ACTION,
    navbarTitle: string,
    openSidebar: TLoanSidebar;
    drawerOpen: boolean;
    leftSidebarExpanded: boolean;
    formElementsSortingEnabled: boolean;
    formElementNavigator: typeof FormElementNavigator[keyof typeof FormElementNavigator];
    formElementTreeMapDepth: number;
    hiddenPhases: string[];
    formElementTreeMapHideFiles: boolean;
    lgUpDrawerOpen: boolean;
    userSettingsFetched: boolean;
    messagesExpanded: boolean;
    activeOverviewView: TLoanOverviewView;
    agreedToTOS: boolean;
    loansFilter: LoansFilterType;
    loansFilterTeamMembersIds: string[];
    keyPreferences: Record<string, any>;
    timezoneId: string;
    zipFileActionCallback?: (action: ZIP_FILE_ACTION) => void;
    sendEmailAddRemoveUser: boolean,
    sendEmailAppMessage: boolean,
    sendEmailLoanStatus: boolean,
    sendTextAddRemoveUser: boolean,
    sendTextAppMessage: boolean,
    sendTextLoanStatus: boolean,
}

export const initialState: UiState = {
    settingsDrawerVisible: false,
    isDraggingFiles: false,
    previewMode: false,
    timezoneId: '',
    connectionError: false,
    closeChatAfterAction: false,
    useAlternativeFilePreviewer: false,
    fileElementUploader: null,
    camScanMode: 'GRAYSCALE',
    hiddenPhases: [],
    dadZipFileHandling: ZipFileAction.ASK,
    navbarTitle: '',
    openSidebar: null,
    leftSidebarExpanded: false,
    formElementsSortingEnabled: true,
    drawerOpen: undefined,
    formElementNavigator: FormElementNavigator.Simple,
    formElementTreeMapDepth: 2,
    formElementTreeMapHideFiles: false,
    lgUpDrawerOpen: false,
    userSettingsFetched: false,
    messagesExpanded: false,
    activeOverviewView: LoanOverviewView.Tasks,
    agreedToTOS: false,
    loansFilter: LoansKanbanFilter.LENDER_LOANS,
    loansFilterTeamMembersIds: [],
    keyPreferences: {},
    sendEmailAddRemoveUser: true,
    sendEmailAppMessage: true,
    sendEmailLoanStatus: true,
    sendTextAddRemoveUser: true,
    sendTextAppMessage: true,
    sendTextLoanStatus: true,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        hideSettingsDrawer(state: UiState): void {
            state.settingsDrawerVisible = false
        },
        showSettingsDrawer(state: UiState): void {
            state.settingsDrawerVisible = true;
        },
        setIsDraggingFiles(state: UiState, action: { payload: boolean }): void {
            state.isDraggingFiles = action.payload;
        },
        setIsPreviewMode(state: UiState, action: { payload: boolean }): void {
            state.previewMode = action.payload;
        },
        toggleConnectionError(state: UiState, action: { payload: boolean }): void {
            state.connectionError = action.payload;
        },
        setTimezoneId(state: UiState, action: { payload: string }): void {
            state.timezoneId = action.payload;
        },
        setCloseChatAfterAction(state: UiState, action: { payload: boolean }): void {
            state.closeChatAfterAction = action.payload;
        },
        setUseAlternativeFilePreviewer(state: UiState, action: { payload: boolean }): void {
            state.useAlternativeFilePreviewer = action.payload;
        },
        setFileElementUploader(state: UiState, action: { payload: FORM_ELEMENT_UPLOADER }): void {
            state.fileElementUploader = action.payload;
        },
        setCamScanMode(state: UiState, action: { payload: CAM_SCAN_MODE }): void {
            state.camScanMode = action.payload;
        },
        setZipFileActionCallback(state: UiState, action: { payload: (action: ZIP_FILE_ACTION) => void }): void {
            state.zipFileActionCallback = action.payload;
        },
        setDadZipFileHandling(state: UiState, action: { payload: ZIP_FILE_ACTION }): void {
            state.dadZipFileHandling = action.payload;
        },
        setNavbarTitle(state: UiState, action: { payload: string }): void {
            state.navbarTitle = action.payload;
        },
        setOpenLoanSidebar(state: UiState, action: { payload: TLoanSidebar }): void {
            state.openSidebar = action.payload;
        },
        setLeftSidebarExpanded(state: UiState, action: { payload: boolean }): void {
            state.leftSidebarExpanded = action.payload;
        },
        setFormElementsSortingEnabled(state: UiState, action: { payload: boolean }): void {
            state.formElementsSortingEnabled = action.payload;
        },
        setDrawerOpen(state: UiState, action: { payload: boolean }): void {
            state.drawerOpen = action.payload;
        },
        setFormElementNavigator(state: UiState, action: { payload: typeof FormElementNavigator[keyof typeof FormElementNavigator] }): void {
            state.formElementNavigator = action.payload;
        },
        setFormElementTreeMapDepth(state: UiState, action: { payload: number }): void {
            state.formElementTreeMapDepth = action.payload;
        },
        setFormElementTreeMapHideFiles(state: UiState, action: { payload: boolean }): void {
            state.formElementTreeMapHideFiles = action.payload;
        },
        openDrawerLgAndUp(state: UiState): void {
            state.lgUpDrawerOpen = true;
            state.drawerOpen = true;
        },
        setHiddenPhases(state: UiState, action: { payload: string[] }): void {
            state.hiddenPhases = action.payload;
        },
        setUserSettingsFetched(state: UiState, action: { payload: boolean }): void {
            state.userSettingsFetched = action.payload;
        },
        setMessagesExpanded(state: UiState, action: { payload: boolean }): void {
            state.messagesExpanded = action.payload;
        },
        setActiveOverviewView(state: UiState, action: { payload: TLoanOverviewView }): void {
            state.activeOverviewView = action.payload;
        },
        setAgreedToTOS(state: UiState, action: { payload: boolean }): void {
            state.agreedToTOS = action.payload;
        },
        setLoansFilter(state: UiState, action: { payload: { loansFilter: LoansFilterType, ids: string[] } }): void {
            state.loansFilter = action.payload.loansFilter;
            state.loansFilterTeamMembersIds = action.payload.ids || [];
        },
        setKeyPreference(state: UiState, action: { payload: { key: string, value: any } }): void {
            state.keyPreferences[action.payload.key] = action.payload.value;
        },
        setSendEmailAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAddRemoveUser = action.payload;
        },
        setSendEmailAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAppMessage = action.payload;
        },
        setSendEmailLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendEmailLoanStatus = action.payload;
        },
        setSendTextAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendTextAddRemoveUser = action.payload;
        },
        setSendTextAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendTextAppMessage = action.payload;
        },
        setSendTextLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendTextLoanStatus = action.payload;
        }
    }
});

export const { reducer, actions: uiActions } = uiSlice;

export const setMessagesExpanded = (expanded: boolean) => dispatch => {
    dispatch(uiSlice.actions.setMessagesExpanded(expanded));
}

export const setUserSettingsFetched = (payload: boolean) => dispatch => {
    dispatch(uiSlice.actions.setUserSettingsFetched(payload));
}

export const toggleDrawer = (open: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setDrawerOpen(open));
}

export const setAgreedToTOS = (agreed: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setAgreedToTOS(agreed));
}

export const openDrawerLgAndUp = (): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.openDrawerLgAndUp());
}

export const setFormElementTreeMapDepth = (depth: number): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementTreeMapDepth(depth));
}

export const setActiveOverviewView = (view: TLoanOverviewView): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setActiveOverviewView(view));
}

export const setFormElementTreeMapHideFiles = (hideFiles: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementTreeMapHideFiles(hideFiles));
}

export const setFormElementNavigator = (navigator: typeof FormElementNavigator[keyof typeof FormElementNavigator]): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementNavigator(navigator));
}

export const setFormElementsSortingEnabled = (enabled: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setFormElementsSortingEnabled(enabled));
}

export const setLeftSidebarExpanded = (expanded: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setLeftSidebarExpanded(expanded));
}

export const setHiddenPhases = (statuses: string[]): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setHiddenPhases(statuses));
}

export const setOpenLoanSidebar = (openSidebar: TLoanSidebar): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setOpenLoanSidebar(openSidebar));
};

export const setIsDraggingFiles = (isDraggingFiles: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setIsDraggingFiles(isDraggingFiles));
};

export const showSettingsDrawer = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(uiSlice.actions.showSettingsDrawer());
};

export const hideSettingsDrawer = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(uiSlice.actions.hideSettingsDrawer());
};

export const setIsPreviewMode = (previewMode: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setIsPreviewMode(previewMode));
};

export const toggleConnectionError = (connectionError?: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.toggleConnectionError(connectionError));
};

export const setCloseChatAfterAction = (closeChatAfterAction: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setCloseChatAfterAction(closeChatAfterAction));
};

export const setUseAlternativeFilePreviewer = (useAlternativeFilePreviewer: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setUseAlternativeFilePreviewer(useAlternativeFilePreviewer));
};

export const setFileElementUploader = (uploader: FORM_ELEMENT_UPLOADER): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setFileElementUploader(uploader));
};

export const setCamScanMode = (camScanMode: CAM_SCAN_MODE): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setCamScanMode(camScanMode));
};

export const setTimezoneId = (timezoneId: string): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setTimezoneId(timezoneId));
};

export const setZipFileActionCallback = (callback: (action: ZIP_FILE_ACTION) => void): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setZipFileActionCallback(callback));
};

export const setDadZipFileHandling = (action: ZIP_FILE_ACTION): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setDadZipFileHandling(action));
};

export const setNavbarTitle = (title: string): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setNavbarTitle(title));
};

export const setSendEmailAddRemoveUser = (sendEmailAddRemoveUser: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailAddRemoveUser(sendEmailAddRemoveUser));
};

export const setSendEmailAppMessage = (sendEmailAppMessage: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailAppMessage(sendEmailAppMessage));
};

export const setSendEmailLoanStatus = (sendEmailLoanStatus: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailLoanStatus(sendEmailLoanStatus));
};

export const setSendTextAddRemoveUser = (sendTextAddRemoveUser: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextAddRemoveUser(sendTextAddRemoveUser));
};

export const setSendTextAppMessage = (sendTextAppMessage: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextAppMessage(sendTextAppMessage));
};

export const setSendTextLoanStatus = (sendTextLoanStatus: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextLoanStatus(sendTextLoanStatus));
};

export const handleRequestError = (error: any) => (dispatch, getState) => {
    const { view: { loggedInUserId } } = getState();
    if (error?.status >= 500 || error?.message === 'Failed to fetch') {
        dispatch(uiSlice.actions.toggleConnectionError(true));
    } else if ([UNAUTHORIZED_ERROR_STATUS, 403].includes(error?.status) && !!loggedInUserId) {
        intervalManager.clearAll();
        console.error('request error, reloading page', error)
        document.location.reload();
        notifyBugTracker(error);
    } else {
        dispatch(uiSlice.actions.toggleConnectionError(false));
    }
};

export const getKeyPreference = (key: string): AppThunkPromise<void> => async (dispatch): Promise<void> => {
    const result = await api.getPreference(key)
    dispatch(uiSlice.actions.setKeyPreference({ key, value: JSON.parse(decodeURIComponent(result.value)) }));
};

export const saveKeyPreference = (key: string, value: any): AppThunkPromise<void> => (dispatch, getState) => {
    const oldValue = keyPreferenceSelector(key)(getState());
    dispatch(uiSlice.actions.setKeyPreference({ key, value }));
    return new Promise((resolve, reject) => {
        api.savePreference(key, JSON.stringify(value)).then(() => {
            resolve();
        }).catch((error) => {
            dispatch(uiSlice.actions.setKeyPreference({ key, value: oldValue }));
            reject(error);
        });
    });
}

export const keyPreferenceSelector = (key: string) => createSelector(
    (state: RootState) => state.ui.keyPreferences,
    (keyPreferences) => keyPreferences[key]
);


export const uiSelector = createSelector((state: RootState) => state.ui, ui => ui);
export const selectDrawerOpen = createSelector((state: RootState) => state.ui, ui => ui.drawerOpen);
export const selectConnectionError = createSelector((state: RootState) => state.ui, ui => ui.connectionError);

export const hiddenPhasesSelector = createSelector([
    (state: RootState) => state.ui
], (ui) => ui.hiddenPhases);

export const loansFilterSelector = createSelector<[(state: RootState) => string[], (state: RootState) => LoansFilterType], {
    teamMembersIds: string[],
    loansFilter: LoansFilterType,
}>([
    (state: RootState) => state.ui.loansFilterTeamMembersIds,
    (state: RootState) => state.ui.loansFilter
], (ids, loansFilter) => ({
    teamMembersIds: ids,
    loansFilter: loansFilter,
}))

export default uiSlice;
